import React, { useState } from 'react';
import '../../styles/components/forms.css';
import '../../styles/modals/support-modal.css';

function SupportContent() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    issueType: '',
    description: ''
  });
  
  const [submitStatus, setSubmitStatus] = useState({
    submitted: false,
    success: false,
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Send form data to the PHP handler
      const response = await fetch('/api/support-handler.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });
      
      const result = await response.json();
      
      if (response.ok) {
        setSubmitStatus({
          submitted: true,
          success: true,
          message: result.message
        });
        
        // Reset form after submission
        setFormData({
          name: '',
          email: '',
          issueType: '',
          description: ''
        });
      } else {
        setSubmitStatus({
          submitted: true,
          success: false,
          message: result.message || 'Something went wrong. Please try again.'
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus({
        submitted: true,
        success: false,
        message: 'Network error. Please check your connection and try again.'
      });
    }
  };

  return (
    <div className="support-modal-content">
      {submitStatus.submitted && submitStatus.success ? (
        <div className="submission-success">
          <h3>Support Request Submitted</h3>
          <p>{submitStatus.message}</p>
          <button 
            className="btn btn-primary mt-3"
            onClick={() => setSubmitStatus({ submitted: false, success: false, message: '' })}
          >
            Submit Another Request
          </button>
        </div>
      ) : (
        <>
          <p className="support-intro">
            Need technical assistance with your CLOUD VIRTUE solutions? Our support team is here to help.
            Submit your request below and we'll respond within 24-48 business hours.
          </p>
          
          <form className="support-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name" className="form-label">Name *</label>
              <input
                type="text"
                id="name"
                name="name"
                className="form-control"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="email" className="form-label">Email Address *</label>
              <input
                type="email"
                id="email"
                name="email"
                className="form-control"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="issueType" className="form-label">Issue Type *</label>
              <select
                id="issueType"
                name="issueType"
                className="form-control"
                value={formData.issueType}
                onChange={handleChange}
                required
              >
                <option value="">Select Issue Type</option>
                <option value="technical">Technical Issue</option>
                <option value="billing">Billing Question</option>
                <option value="account">Account Management</option>
                <option value="feature">Feature Request</option>
                <option value="other">Other</option>
              </select>
            </div>
            
            <div className="form-group">
              <label htmlFor="description" className="form-label">Description *</label>
              <textarea
                id="description"
                name="description"
                className="form-control"
                rows="4"
                placeholder="Please provide details about your issue or question"
                value={formData.description}
                onChange={handleChange}
                required
              ></textarea>
            </div>
            
            <div className="form-privacy-note">
              <small>By submitting this form, you agree to our <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</small>
            </div>
            
            <div className="form-actions">
              <button type="submit" className="btn btn-primary">Submit Request</button>
            </div>
          </form>
        </>
      )}
    </div>
  );
}

export default SupportContent;
