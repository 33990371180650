import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/pages/not-found.css';

function NotFound() {
  useEffect(() => {
    document.title = 'Page Not Found | CLOUD VIRTUE';
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="not-found-page">
      <div className="container">
        <div className="not-found-content">
          <h1>404</h1>
          <h2>Page Not Found</h2>
          <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
          <Link to="/" className="primary-button">Return to Homepage</Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
