import React from 'react';
import '../styles/policy-pages.css';

function Privacy() {
  // Scroll to top on component mount
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-wrapper">
      <section className="container section">
        <div className="policy-content">
          <h1>Privacy Policy</h1>
          <p className="last-updated">Last Updated: March 24, 2025</p>

          <div className="policy-section">
            <h2>1. Introduction</h2>
            <p>
              At CLOUD VIRTUE ("we," "our," or "us"), we respect your privacy and are committed to protecting your personal data. 
              This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website at 
              <a href="https://cloudvirtue.com"> cloudvirtue.com</a> or use our services, including domain registration, cloud hosting, 
              AI integration, and no-code development solutions.
            </p>
            <p>
              Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the site or use our services.
            </p>
          </div>

          <div className="policy-section">
            <h2>2. Information We Collect</h2>
            
            <h3>2.1 Personal Information</h3>
            <p>We may collect personal information that you voluntarily provide to us when you:</p>
            <ul>
              <li>Register for an account</li>
              <li>Purchase or subscribe to our services</li>
              <li>Request information about our offerings</li>
              <li>Participate in promotions or surveys</li>
              <li>Contact our support team</li>
              <li>Apply for employment</li>
            </ul>
            <p>This information may include:</p>
            <ul>
              <li>Name, email address, phone number, and billing address</li>
              <li>Payment information (credit card details, billing information)</li>
              <li>Business information (company name, job title)</li>
              <li>Technical requirements and project specifications</li>
              <li>Communication preferences</li>
              <li>Employment history and qualifications (for job applicants)</li>
            </ul>

            <h3>2.2 Automatically Collected Information</h3>
            <p>When you access our website or services, we may automatically collect certain information, including:</p>
            <ul>
              <li>IP address and device information</li>
              <li>Browser type and version</li>
              <li>Operating system</li>
              <li>Pages visited and interaction with our site</li>
              <li>Referral sources</li>
              <li>Time and date of visits</li>
              <li>Service usage patterns and preferences</li>
            </ul>
          </div>

          <div className="policy-section">
            <h2>3. How We Use Your Information</h2>
            <p>We use the information we collect for various purposes, including to:</p>
            <ul>
              <li>Provide, maintain, and improve our services</li>
              <li>Process transactions and send related information</li>
              <li>Respond to inquiries and provide customer support</li>
              <li>Send administrative information, such as updates, security alerts, and support messages</li>
              <li>Send marketing communications, if you have opted to receive them</li>
              <li>Personalize your experience with our site and services</li>
              <li>Monitor and analyze usage trends to improve our website and services</li>
              <li>Protect our services and prevent fraud</li>
              <li>Comply with legal obligations</li>
            </ul>
          </div>

          <div className="policy-section">
            <h2>4. Cookies and Similar Technologies</h2>
            <p>
              We use cookies and similar tracking technologies to track activity on our website and hold certain information. 
              Cookies are files with a small amount of data that may include an anonymous unique identifier. 
              They are sent to your browser from a website and stored on your device.
            </p>
            <p>We use cookies for the following purposes:</p>
            <ul>
              <li>Essential cookies: For the operation of our website</li>
              <li>Analytical/performance cookies: To recognize and count visitors and analyze how users navigate our site</li>
              <li>Functionality cookies: To recognize you when you return to our website</li>
              <li>Targeting cookies: To record your visit to our website, the pages you have visited, and the links you have followed</li>
            </ul>
            <p>
              You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. 
              However, if you do not accept cookies, you may not be able to use some portions of our site.
            </p>
          </div>

          <div className="policy-section">
            <h2>5. Data Sharing and Disclosure</h2>
            <p>We may share your personal information in the following situations:</p>
            <ul>
              <li><strong>Service Providers:</strong> We may share your information with third-party vendors, service providers, and contractors who perform services for us or on our behalf.</li>
              <li><strong>Business Transfers:</strong> If we are involved in a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.</li>
              <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
              <li><strong>Consent:</strong> We may share your information with your consent.</li>
            </ul>
          </div>

          <div className="policy-section">
            <h2>6. Data Security</h2>
            <p>
              We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, 
              alteration, disclosure, or destruction. However, no method of transmission over the Internet or electronic storage is 100% secure. 
              Therefore, while we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.
            </p>
          </div>

          <div className="policy-section">
            <h2>7. Data Retention</h2>
            <p>
              We will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. 
              We will retain and use your information to the extent necessary to comply with our legal obligations, 
              resolve disputes, enforce our policies, and as otherwise described in this policy.
            </p>
          </div>

          <div className="policy-section">
            <h2>8. Your Privacy Rights</h2>
            <p>Depending on your location, you may have the following rights regarding your personal information:</p>
            <ul>
              <li>The right to access the personal information we have about you</li>
              <li>The right to request correction of inaccurate personal information</li>
              <li>The right to request deletion of your personal information</li>
              <li>The right to object to processing of your personal information</li>
              <li>The right to request restriction of processing of your personal information</li>
              <li>The right to data portability</li>
              <li>The right to withdraw consent</li>
            </ul>
            <p>
              To exercise these rights, please contact us using the contact information provided at the end of this Privacy Policy.
            </p>
          </div>

          <div className="policy-section">
            <h2>9. Children's Privacy</h2>
            <p>
              Our services are not intended for use by children under the age of 16. We do not knowingly collect personal information from children under 16. 
              If we become aware that we have collected personal information from a child under 16 without verification of parental consent, 
              we will take steps to remove that information from our servers.
            </p>
          </div>

          <div className="policy-section">
            <h2>10. International Data Transfers</h2>
            <p>
              Your information may be transferred to — and maintained on — computers located outside of your state, province, country, 
              or other governmental jurisdiction where the data protection laws may differ from those in your jurisdiction. 
              If you are located outside the United States and choose to provide information to us, please note that we transfer the data to the United States and process it there.
            </p>
          </div>

          <div className="policy-section">
            <h2>11. Changes to This Privacy Policy</h2>
            <p>
              We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date.
              You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
            </p>
          </div>

          <div className="policy-section">
            <h2>12. Contact Us</h2>
            <p>
              If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
            </p>
            <p className="contact-info">
              CLOUD VIRTUE<br />
              Email: <a href="mailto:contact@cloudvirtue.com">contact@cloudvirtue.com</a><br />
              Website: <a href="https://cloudvirtue.com">cloudvirtue.com</a>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Privacy;
