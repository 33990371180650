import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import HostingHero from '../components/sections/heroes/HostingHero';
import '../styles/pages/hosting.css';

function Hosting() {
  const [currentStep, setCurrentStep] = useState(0);
  const [userType, setUserType] = useState(null); // 'new' or 'existing'
  const [hasDomain, setHasDomain] = useState(null); // true or false
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [domainName, setDomainName] = useState('');
  
  // Plans data
  const hostingPlans = [
    {
      id: 'starter',
      name: 'Starter',
      price: 5.99,
      storage: '10 GB',
      bandwidth: 'Unmetered',
      websites: 1,
      features: ['Free SSL Certificate', 'Daily Backups', '24/7 Support']
    },
    {
      id: 'business',
      name: 'Business',
      popular: true,
      price: 12.99,
      storage: '50 GB',
      bandwidth: 'Unmetered',
      websites: 5,
      features: ['Free SSL Certificate', 'Daily Backups', '24/7 Support', 'Free Domain for 1 Year', 'Performance Boost']
    },
    {
      id: 'enterprise',
      name: 'Enterprise',
      price: 24.99,
      storage: '100 GB',
      bandwidth: 'Unmetered',
      websites: 'Unlimited',
      features: ['Free SSL Certificate', 'Daily Backups', '24/7 Support', 'Free Domain for 1 Year', 'Performance Boost', 'Dedicated Resources', 'Priority Support']
    }
  ];
  
  // Navigation functions
  const nextStep = () => setCurrentStep(prev => prev + 1);
  const prevStep = () => setCurrentStep(prev => prev - 1);
  const goToStep = (step) => setCurrentStep(step);
  
  // Reset wizard on component unmount
  useEffect(() => {
    document.title = 'Web Hosting | CLOUD VIRTUE';
    return () => {
      setCurrentStep(0);
      setUserType(null);
      setHasDomain(null);
      setSelectedPlan(null);
    };
  }, []);
  
  // Steps content
  const steps = [
    // Step 0: Choose customer type
    {
      title: 'Welcome to CLOUD VIRTUE Hosting',
      content: (
        <div className="step-content user-type-step">
          <h3>Let's get started with your web hosting</h3>
          <p>Tell us a bit about yourself so we can better assist you:</p>
          
          <div className="option-cards">
            <div 
              className={`option-card ${userType === 'existing' ? 'selected' : ''}`}
              onClick={() => setUserType('existing')}
            >
              <div className="option-icon existing-customer"></div>
              <h3>I'm an Existing Customer</h3>
              <p>I already have a CLOUD VIRTUE account and want to manage my hosting or add new services</p>
            </div>
            
            <div 
              className={`option-card ${userType === 'new' ? 'selected' : ''}`}
              onClick={() => setUserType('new')}
            >
              <div className="option-icon new-customer"></div>
              <h3>I'm a New Customer</h3>
              <p>I'm new to CLOUD VIRTUE and want to set up hosting for my website</p>
            </div>
          </div>
          
          <div className="step-actions">
            <button 
              className="primary-button" 
              onClick={nextStep} 
              disabled={userType === null}
            >
              Continue
            </button>
          </div>
        </div>
      )
    },
    
    // Step 1: Domain question
    {
      title: 'Your Domain Status',
      content: (
        <div className="step-content domain-step">
          <h3>Do you already have a domain name?</h3>
          <p>Let us know if you already have a domain name or need to register one.</p>
          
          <div className="option-cards">
            <div 
              className={`option-card ${hasDomain === true ? 'selected' : ''}`}
              onClick={() => setHasDomain(true)}
            >
              <div className="option-icon has-domain"></div>
              <h3>Yes, I have a domain</h3>
              <p>I'll use my existing domain with my new hosting</p>
            </div>
            
            <div 
              className={`option-card ${hasDomain === false ? 'selected' : ''}`}
              onClick={() => setHasDomain(false)}
            >
              <div className="option-icon no-domain"></div>
              <h3>No, I need a domain</h3>
              <p>I want to register a new domain name for my website</p>
            </div>
          </div>
          
          {hasDomain === true && (
            <div className="domain-input-section">
              <label htmlFor="domainName">Please enter your domain name:</label>
              <input
                type="text"
                id="domainName"
                value={domainName}
                onChange={(e) => setDomainName(e.target.value)}
                placeholder="example.com"
              />
            </div>
          )}
          
          <div className="step-actions">
            <button className="secondary-button" onClick={prevStep}>Back</button>
            <button 
              className="primary-button" 
              onClick={nextStep} 
              disabled={hasDomain === null || (hasDomain === true && !domainName)}
            >
              Continue
            </button>
          </div>
        </div>
      )
    },
    
    // Step 2: Select Plan
    {
      title: 'Choose Your Hosting Plan',
      content: (
        <div className="step-content plans-step">
          <h3>Select the hosting plan that fits your needs</h3>
          <p>All plans include our reliable infrastructure and 24/7 technical support.</p>
          
          <div className="plans-grid">
            {hostingPlans.map(plan => (
              <div 
                key={plan.id}
                className={`plan-card ${plan.popular ? 'popular' : ''} ${selectedPlan === plan.id ? 'selected' : ''}`}
                onClick={() => setSelectedPlan(plan.id)}
              >
                {plan.popular && <div className="popular-badge">Most Popular</div>}
                <h3>{plan.name}</h3>
                <div className="plan-price">
                  <span className="price">${plan.price}</span>
                  <span className="period">/month</span>
                </div>
                <ul className="plan-features">
                  <li><strong>{plan.storage} Storage</strong></li>
                  <li><strong>{plan.bandwidth} Bandwidth</strong></li>
                  <li><strong>{plan.websites} {typeof plan.websites === 'number' ? (plan.websites === 1 ? 'Website' : 'Websites') : plan.websites}</strong></li>
                  {plan.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
                <button 
                  className={`${selectedPlan === plan.id ? 'primary-button' : 'secondary-button'} select-plan-btn`}
                >
                  {selectedPlan === plan.id ? 'Selected' : 'Select Plan'}
                </button>
              </div>
            ))}
          </div>
          
          <div className="step-actions">
            <button className="secondary-button" onClick={prevStep}>Back</button>
            <button 
              className="primary-button" 
              onClick={nextStep} 
              disabled={!selectedPlan}
            >
              Continue
            </button>
          </div>
        </div>
      )
    },
    
    // Step 3: Configuration options
    {
      title: 'Configure Your Hosting',
      content: (
        <div className="step-content config-step">
          <h3>Customize Your Hosting Environment</h3>
          <p>These settings can be changed later in your control panel.</p>
          
          <div className="config-options">
            <div className="config-option">
              <h4>Server Location</h4>
              <select defaultValue="us-east">
                <option value="us-east">US East (Virginia)</option>
                <option value="us-west">US West (Oregon)</option>
                <option value="eu-central">EU (Frankfurt)</option>
                <option value="asia-pacific">Asia Pacific (Singapore)</option>
              </select>
              <p className="option-hint">Choose a server location nearest to your target audience for best performance</p>
            </div>
            
            <div className="config-option">
              <h4>Control Panel</h4>
              <select defaultValue="cpanel">
                <option value="cpanel">cPanel</option>
                <option value="plesk">Plesk</option>
                <option value="directadmin">DirectAdmin</option>
              </select>
              <p className="option-hint">Select the control panel interface you prefer for managing your hosting</p>
            </div>
            
            <div className="config-option">
              <h4>Software Stack</h4>
              <div className="checkbox-group">
                <label>
                  <input type="checkbox" defaultChecked />
                  PHP
                </label>
                <label>
                  <input type="checkbox" />
                  Node.js
                </label>
                <label>
                  <input type="checkbox" />
                  Python
                </label>
                <label>
                  <input type="checkbox" />
                  Ruby
                </label>
              </div>
              <p className="option-hint">Select the programming languages you need for your website</p>
            </div>
            
            <div className="config-option">
              <h4>Databases</h4>
              <div className="checkbox-group">
                <label>
                  <input type="checkbox" defaultChecked />
                  MySQL
                </label>
                <label>
                  <input type="checkbox" />
                  PostgreSQL
                </label>
                <label>
                  <input type="checkbox" />
                  MongoDB
                </label>
              </div>
              <p className="option-hint">Select the database technologies you'll need</p>
            </div>
          </div>
          
          <div className="step-actions">
            <button className="secondary-button" onClick={prevStep}>Back</button>
            <button className="primary-button" onClick={nextStep}>Continue</button>
          </div>
        </div>
      )
    },
    
    // Step 4: Summary & Checkout
    {
      title: 'Review & Complete',
      content: (
        <div className="step-content summary-step">
          <h3>Order Summary</h3>
          <p>Please review your hosting order details before proceeding to checkout.</p>
          
          <div className="order-summary">
            <div className="summary-item">
              <div className="summary-label">Account Type</div>
              <div className="summary-value">{userType === 'new' ? 'New Customer' : 'Existing Customer'}</div>
            </div>
            
            <div className="summary-item">
              <div className="summary-label">Domain Status</div>
              <div className="summary-value">
                {hasDomain ? `Using existing domain: ${domainName}` : 'Will register a new domain'}
              </div>
            </div>
            
            <div className="summary-item">
              <div className="summary-label">Hosting Plan</div>
              <div className="summary-value">
                {selectedPlan && hostingPlans.find(p => p.id === selectedPlan).name}
                <span className="price-tag">
                  ${selectedPlan && hostingPlans.find(p => p.id === selectedPlan).price}/mo
                </span>
              </div>
            </div>
            
            <div className="summary-item total-item">
              <div className="summary-label">Total (Monthly)</div>
              <div className="summary-value total-value">
                ${selectedPlan && hostingPlans.find(p => p.id === selectedPlan).price}
              </div>
            </div>
          </div>
          
          <div className="step-actions checkout-actions">
            <button className="secondary-button" onClick={prevStep}>Back</button>
            
            {userType === 'existing' ? (
              <button className="primary-button">Proceed to Control Panel</button>
            ) : (
              <button className="primary-button">Proceed to Checkout</button>
            )}
          </div>
        </div>
      )
    }
  ];
  
  return (
    <div className="hosting-page">
      <HostingHero />
      <section className="hosting-features" id="hosting-features">
        <div className="container">
          <div className="section-header">
            <h2>All Hosting Plans Include</h2>
            <p>Enterprise-level features designed for businesses of all sizes</p>
          </div>
          
          <div className="features-grid">
            <div className="feature-item">
              <div className="feature-icon uptime-icon"></div>
              <h3>99.9% Uptime</h3>
              <p>We guarantee that your website will be accessible 99.9% of the time</p>
            </div>
            
            <div className="feature-item">
              <div className="feature-icon support-icon"></div>
              <h3>24/7/365 Support</h3>
              <p>Our technical support team is available around the clock</p>
            </div>
            
            <div className="feature-item">
              <div className="feature-icon security-icon"></div>
              <h3>Advanced Security</h3>
              <p>Built-in protection against DDoS attacks, malware, and other threats</p>
            </div>
            
            <div className="feature-item">
              <div className="feature-icon backup-icon"></div>
              <h3>Daily Backups</h3>
              <p>Automated daily backups with 30-day retention</p>
            </div>
            
            <div className="feature-item">
              <div className="feature-icon cdn-icon"></div>
              <h3>Global CDN</h3>
              <p>Content delivery network for ultra-fast loading worldwide</p>
            </div>
            
            <div className="feature-item">
              <div className="feature-icon migration-icon"></div>
              <h3>Free Migration</h3>
              <p>We'll move your existing website to our servers at no cost</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Hosting;
