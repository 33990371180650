/**
 * Configuration file for domain facts to be displayed in the DomainsHero component
 */

// Simple domain icon SVG
export const domainIcon = `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round">
  <path d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20z" fill="none"></path>
  <path d="M2 12h20" fill="none"></path>
  <path d="M12 2a8 8 0 0 1 4 14.5c-1 1-2.5 1.5-4 1.5s-3-.5-4-1.5a8 8 0 0 1 4-14.5z" fill="none"></path>
</svg>
`;

// Array of creative domain facts
export const domainFacts = [
  {
    id: 1,
    fact: "73% of consumers trust businesses with professional domain names more than those using free email services or social media handles.",
    title: "Trust Factor"
  },
  {
    id: 2,
    fact: "The domain 'Insure.com' sold for $16 million in 2009, making it one of the most expensive domain purchases in history.",
    title: "Premium Value"
  },
  {
    id: 3,
    fact: "A domain name is like digital real estate – once you own it, no one else in the world can claim that exact address.",
    title: "Digital Property"
  },
  {
    id: 4,
    fact: "Businesses with exact-match domain names receive up to 25% more organic search traffic than those with non-matching domains.",
    title: "SEO Advantage"
  },
  {
    id: 5,
    fact: "The first ever registered domain name was Symbolics.com in 1985, predating even the web itself. It still exists today!",
    title: "Internet History"
  }
];
