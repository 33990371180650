class ArticleService {
  constructor() {
    this.CACHE_KEY = 'ai_articles_cache';
    this.LAST_FETCH_KEY = 'ai_articles_last_fetch';
    this.UPDATE_HOUR = 6; // 6 AM daily update
  }
  
  /**
   * Check if articles need refreshing (after 6am and not fetched today)
   */
  shouldRefreshArticles() {
    const lastFetch = localStorage.getItem(this.LAST_FETCH_KEY);
    const now = new Date();
    const today = new Date().setHours(0, 0, 0, 0);
    
    // If no last fetch or it's a different day
    if (!lastFetch || new Date(lastFetch).setHours(0, 0, 0, 0) < today) {
      // Check if current time is past update hour (6 AM)
      return now.getHours() >= this.UPDATE_HOUR;
    }
    
    return false;
  }
  
  /**
   * Get the next scheduled update time
   */
  getNextUpdateTime() {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(this.UPDATE_HOUR, 0, 0, 0);
    
    // If it's before 6 AM today, next update is at 6 AM today
    if (now.getHours() < this.UPDATE_HOUR) {
      const today = new Date(now);
      today.setHours(this.UPDATE_HOUR, 0, 0, 0);
      return today;
    }
    
    return tomorrow;
  }
  
  /**
   * Fetch articles from all sources
   */
  async fetchArticles() {
    try {
      const response = await fetch('/api/fetch-content.php');
      
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      
      const data = await response.json();
      
      // Store in local storage for caching
      localStorage.setItem(this.CACHE_KEY, JSON.stringify(data));
      localStorage.setItem(this.LAST_FETCH_KEY, new Date().toISOString());
      
      return data;
    } catch (error) {
      console.error("Error fetching articles:", error);
      // Return cached data if available
      const cached = this.getCachedArticles();
      return cached.articles || [];
    }
  }
  
  /**
   * Get cached articles and their metadata
   */
  getCachedArticles() {
    try {
      const articles = JSON.parse(localStorage.getItem(this.CACHE_KEY)) || [];
      const lastFetch = localStorage.getItem(this.LAST_FETCH_KEY);
      const lastFetchDate = lastFetch ? new Date(lastFetch) : null;
      const nextUpdate = this.getNextUpdateTime();
      
      return {
        articles,
        lastFetchDate,
        nextUpdate
      };
    } catch (error) {
      console.error("Error reading cached articles:", error);
      return { articles: [], lastFetchDate: null, nextUpdate: null };
    }
  }
  
  /**
   * Find the best article to feature
   */
  findFeaturedArticle(articles) {
    if (!articles || articles.length === 0) return null;
    
    // First look for articles specifically in the AI category
    let featured = articles.find(post => post.category === 'ai-integrations');
    
    // If no AI category article found, look for AI in title or content
    if (!featured) {
      featured = articles.find(post => 
        post.title.toLowerCase().includes('ai') || 
        post.excerpt.toLowerCase().includes('artificial intelligence') ||
        post.excerpt.toLowerCase().includes('machine learning')
      );
    }
    
    // Default to the first article if no AI-focused content found
    return featured || articles[0];
  }
  
  /**
   * Force refresh articles immediately
   */
  async forceRefresh() {
    const articles = await this.fetchArticles();
    return {
      articles,
      lastFetchDate: new Date(),
      nextUpdate: this.getNextUpdateTime()
    };
  }
}

export default new ArticleService();
