/**
 * Article Modal Configuration
 * Centralized configuration for article-related settings
 */

// Default fallback images for articles by category
export const DEFAULT_IMAGES = {
  // Main fallback used if no category-specific image is found
  default: '/assets/images/ai/tech-integration.jpg',
  
  // Category-specific fallbacks
  'ai-integrations': '/assets/images/ai/tech-integration.jpg',
  'tools': '/assets/images/illustrations/dns-http-dev.png',
  'automations': '/assets/images/ai/case-study3.jpg',
  'case-studies': '/assets/images/ai/case-study1.jpg',
  'workflows': '/assets/images/ai/case-study2.jpg',
};

// Get fallback image based on article category
export const getFallbackImage = (category) => {
  return DEFAULT_IMAGES[category] || DEFAULT_IMAGES.default;
};

// Article fetch configuration
export const ARTICLE_CONFIG = {
  cacheTimeInMs: 3600000, // 1 hour
  maxArticlesToShow: 5,
  defaultReadTime: '5 min'
};
