import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../common/Icon';
import { hostingFacts } from '../../../config/hostingFacts';
import './styles/heroes.css';

function HostingHero() {
  const [currentFactIndex, setCurrentFactIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  // Function to handle fact rotation with fade effect
  useEffect(() => {
    const factInterval = setInterval(() => {
      // Fade out
      setIsVisible(false);
      
      // Wait for fade out, then change fact and fade in
      setTimeout(() => {
        setCurrentFactIndex((prevIndex) => (prevIndex + 1) % hostingFacts.length);
        setIsVisible(true);
      }, 500); // Matches CSS transition time
      
    }, 7000); // Change every 7 seconds
    
    return () => clearInterval(factInterval);
  }, []);

  // Get current fact
  const currentFact = hostingFacts[currentFactIndex];

  const scrollToFeatures = (e) => {
    e.preventDefault();
    const featuresSection = document.getElementById('hosting-features');
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="page-hero">
      <div className="container">
        <div className="hero-content-wrapper">
          <div className="hero-text-content">
            <h1>Web Hosting by <br/><span className="highlight-text">CLOUD VIRTUE</span></h1>
            <p className="lead">Experience lightning-fast performance with CLOUD VIRTUE's enterprise-grade cloud hosting solutions built for reliability and scalability</p>
            <div className="hero-buttons">
              <Link to="/get-started" className="primary-button">Choose a Plan</Link>
              <a href="#hosting-features" className="secondary-button" onClick={scrollToFeatures}>Browse Features</a>
            </div>
          </div>
          <div className="hero-image-content">
            <div className="featured-domain-card">
              <div className={`domain-fact-container ${isVisible ? 'visible' : 'hidden'}`}>
                <h3>{currentFact.title}</h3>
                <p className="domain-fact">{currentFact.fact}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HostingHero;