import React, { useEffect, useState, useCallback } from 'react';
import DomainsHero from '../components/sections/heroes/DomainsHero';
import '../styles/pages/domains.css';

function Domains() {
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  // Handle modal open - using useCallback to ensure consistent function reference
  const openDomainModal = useCallback(() => {
    console.log('Opening domain modal - button clicked');
    try {
      setIsModalOpen(true);
      document.body.style.overflow = 'hidden'; // Prevent scrolling of background content
      console.log('Modal state set to open');
      
      // Force body style update
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      document.body.style.top = `-${window.scrollY}px`;
      
      console.log('Body styles applied');
    } catch (error) {
      console.error('Error opening modal:', error);
    }
  }, []);
  
  // Handle modal close
  const closeDomainModal = useCallback(() => {
    console.log('Closing domain modal');
    try {
      // Restore scroll position
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.width = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
      
      setIsModalOpen(false);
      document.body.style.overflow = 'auto'; // Restore scrolling
      console.log('Modal closed successfully');
    } catch (error) {
      console.error('Error closing modal:', error);
    }
  }, []);

  // Auto-open the modal when pressing "Back" to ensure a smooth experience
  const handleKeyPress = useCallback((event) => {
    if (event.key === 'Escape' && isModalOpen) {
      closeDomainModal();
    }
  }, [isModalOpen, closeDomainModal]);

  useEffect(() => {
    // Set document title
    document.title = 'Domain Registration | CLOUD VIRTUE';
    
    // Add escape key listener
    window.addEventListener('keydown', handleKeyPress);
    
    // Clean up function
    return () => {
      document.body.style.overflow = 'auto'; // Ensure scrolling is restored if component unmounts
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <div className="domains-page">
      <DomainsHero openModal={openDomainModal} />
      <section className="domains-content-section">
        <div className="container">
          <div className="domains-intro">
            <h2>Find Your Perfect Domain Name</h2>
            <p>Your domain name is your online address and a critical part of your brand identity. CLOUD VIRTUE offers a comprehensive domain registration service with competitive pricing and expert support.</p>
            
            <div className="domain-features">
              <div className="feature">
                <div className="feature-icon search-icon"></div>
                <h3>Domain Search</h3>
                <p>Find available domains with our powerful search tool</p>
              </div>
              
              <div className="feature">
                <div className="feature-icon protect-icon"></div>
                <h3>Privacy Protection</h3>
                <p>Keep your personal information safe with WHOIS privacy</p>
              </div>
              
              <div className="feature">
                <div className="feature-icon manage-icon"></div>
                <h3>Easy Management</h3>
                <p>Manage all your domains in one convenient dashboard</p>
              </div>
            </div>
            
            <button 
              type="button"
              onClick={openDomainModal}
              className="primary-button domain-search-button"
              id="content-domain-button"
              aria-label="Search for domain names"
            >
              Search & Register Domains
            </button>
          </div>
        </div>
      </section>
      
      {/* Full-screen domain search modal */}
      {isModalOpen && (
        <div className="domain-modal-overlay" id="domain-modal">
          <div className="domain-modal-content">
            {isLoading && (
              <div className="domains-loading">
                <div className="spinner"></div>
                <p>Loading domain search...</p>
              </div>
            )}
            <div className="domain-modal-close-container">
              <button 
                className="modal-close"
                onClick={closeDomainModal}
                id="domain-modal-close-btn"
                aria-label="Close modal"
              >
                ×
              </button>
            </div>
            <iframe 
              src="https://cloudvirtue.shopco.com/site/home"
              title="Domain Registration"
              className={`domains-iframe ${isLoading ? 'loading' : 'loaded'}`}
              onLoad={() => setIsLoading(false)}
              frameBorder="0"
              id="domain-search-iframe"
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
}

export default Domains;
