import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { domainFacts } from '../../../config/domainFacts';
import './styles/heroes.css';

function DomainsHero({ openModal }) {
  const [currentFactIndex, setCurrentFactIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  // Function to handle fact rotation with fade effect
  useEffect(() => {
    const factInterval = setInterval(() => {
      // Fade out
      setIsVisible(false);
      
      // Wait for fade out, then change fact and fade in
      setTimeout(() => {
        setCurrentFactIndex((prevIndex) => (prevIndex + 1) % domainFacts.length);
        setIsVisible(true);
      }, 500); // Matches CSS transition time
      
    }, 7000); // Change every 7 seconds
    
    return () => clearInterval(factInterval);
  }, []);

  // Get current fact
  const currentFact = domainFacts[currentFactIndex];

  return (
    <section className="page-hero">
      <div className="container">
        <div className="hero-content-wrapper">
          <div className="hero-text-content">
            <h1>Domain Names from <br/><span className="highlight-text">CLOUD VIRTUE</span></h1>
            <p className="lead">Secure your perfect domain name and establish a professional online presence with CLOUD VIRTUE</p>
            <div className="hero-buttons">
              <button onClick={openModal} className="primary-button">Register a Domain</button>
              <Link to="/hosting" className="secondary-button">Explore Hosting</Link>
            </div>
          </div>
          <div className="hero-image-content">
            <div className="featured-domain-card">
              <div className={`domain-fact-container ${isVisible ? 'visible' : 'hidden'}`}>
                <h3>{currentFact.title}</h3>
                <p className="domain-fact">{currentFact.fact}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default DomainsHero;