import React from 'react';
import { Link } from 'react-router-dom';

function Services() {
  const services = [
    {
      id: 1,
      icon: 'ai-icon',
      title: 'AI Solutions',
      description: 'Strategic guidance on implementing AI solutions tailored to your business needs and goals.',
      link: '/ai-today'  // Updated to match new path
    },
    {
      id: 2,
      icon: 'nocode-icon',
      title: 'No-Code Dev',
      description: 'Build powerful applications and automate workflows without writing a single line of code.',
      link: '/no-code-dev'
    },
    {
      id: 3,
      icon: 'domain-icon',
      title: 'Domain Names',
      description: 'Secure your online identity with our domain registration and management services.',
      link: '/domains'
    },
    {
      id: 4,
      icon: 'host-icon',
      title: 'Web Hosting',
      description: 'Reliable, high-performance web hosting with 99.9% uptime guarantee and 24/7 support.',
      link: '/hosting'
    }
  ];
  
  return (
    <section className="services-section" id="services">
      <div className="container">
        <div className="section-header">
          <h2>Our Services</h2>
          <p>Comprehensive solutions for modern business needs</p>
        </div>
        
        <div className="services-grid">
          {services.map(service => (
            <div className="service-card" key={service.id}>
              <div className={`service-icon ${service.icon}`}></div>
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Services;
