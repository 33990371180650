import React, { useState, useEffect } from 'react';
import NoCodeHero from '../components/sections/heroes/NoCodeHero';
import '../styles/pages/no-code-dev.css';
import StayInformedModal from '../components/modals/StayInformedModal';
import WorksModal from '../components/modals/WorksModal';

const NoCodeDev = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWorksModalOpen, setIsWorksModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="no-code-dev-page">
        <NoCodeHero />
        <section className="nocode-capabilities-section">
          <div className="container">
            <div className="section-header">
              <h2>Our No-Code Capabilities</h2>
              <p>Creating powerful solutions faster and more efficiently than traditional development</p>
            </div>
            
            <div className="capabilities-grid">
              <div className="capability-card">
                <div className="capability-icon webflow-icon"></div>
                <h3>Web Applications</h3>
                <p>Full-featured web applications with databases, user authentication, and complex workflows</p>
              </div>
              
              <div className="capability-card">
                <div className="capability-icon automation-icon"></div>
                <h3>Workflow Automation</h3>
                <p>Automated business processes connecting your existing tools and platforms</p>
              </div>
              
              <div className="capability-card">
                <div className="capability-icon integration-icon"></div>
                <h3>API Integrations</h3>
                <p>Seamless connections between different software systems and data sources</p>
              </div>
              
              <div className="capability-card">
                <div className="capability-icon ai-icon"></div>
                <h3>AI Implementation</h3>
                <p>Practical AI solutions integrated into your workflows without complex coding</p>
              </div>
            </div>
          </div>
        </section>
        
        {/* Stay Informed Modal */}
        <StayInformedModal isOpen={isModalOpen} onClose={closeModal} />
      </div>
    </>
  );
}

export default NoCodeDev;
