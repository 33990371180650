import React, { useState } from 'react';
import '../../styles/components/stay-informed-modal.css';

function StayInformedModal({ isOpen, onClose, posts, loading, lastUpdated, nextUpdate, onRefresh }) {
  const [activeCategory, setActiveCategory] = useState('all');
  
  if (!isOpen) return null;
  
  // Format times for display
  const formatUpdateTime = (date) => {
    if (!date) return '';
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  };
  
  const categories = [
    { id: 'all', name: 'All Topics' },
    { id: 'tools', name: 'No-Code Tools' },
    { id: 'platforms', name: 'Platforms' },
    { id: 'automations', name: 'Automations' },
    { id: 'ai-integrations', name: 'AI Integrations' },
    { id: 'case-studies', name: 'Case Studies' }
  ];
  
  const filteredPosts = activeCategory === 'all'
    ? posts
    : posts.filter(post => post.category === activeCategory);
    
  const handleRefresh = () => {
    if (onRefresh && typeof onRefresh === 'function') {
      onRefresh();
    }
  };
  
  return (
    <div className="stay-informed-overlay" onClick={onClose}>
      <div className="stay-informed-modal" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <div className="modal-header-content">
            <h2>Articles & Insights</h2>
            <div className="modal-update-info">
              {lastUpdated && (
                <span className="update-timestamp">
                  Updated: {formatUpdateTime(lastUpdated)}
                </span>
              )}
              {nextUpdate && (
                <span className="next-update">
                  Next update: {formatUpdateTime(nextUpdate)}
                </span>
              )}
              <button className="refresh-button" onClick={handleRefresh} disabled={loading}>
                <span className={`refresh-icon ${loading ? 'refreshing' : ''}`}>↻</span>
              </button>
            </div>
          </div>
          <button className="modal-close" onClick={onClose}>&times;</button>
        </div>
        
        <div className="topics-navigation">
          <ul className="topics-list">
            {categories.map(category => (
              <li key={category.id} className={activeCategory === category.id ? 'active' : ''}>
                <button onClick={() => setActiveCategory(category.id)}>
                  {category.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
        
        <div className="modal-content">
          <div className="modal-sources-info">
            <p>Articles sourced daily from industry leaders including Makerpad, NoCode.Tech, Zapier, and more</p>
          </div>
          
          <div className="mobile-modal-filters">
            {categories.map(category => (
              <button 
                key={category.id}
                className={`filter-btn ${activeCategory === category.id ? 'active' : ''}`}
                onClick={() => setActiveCategory(category.id)}
              >
                {category.name}
              </button>
            ))}
          </div>
          
          {loading ? (
            <div className="modal-loading">
              <div className="spinner"></div>
              <p>Loading articles...</p>
            </div>
          ) : (
            <div className="modal-articles-grid">
              {filteredPosts.map(post => (
                <div className="modal-article-card" key={post.id}>
                  <div className="article-image" style={{ backgroundImage: `url(${post.image})` }}>
                    <div className="article-source">{post.author}</div>
                  </div>
                  <div className="article-content">
                    <h3>
                      <a href={post.externalUrl} target="_blank" rel="noopener noreferrer">
                        {post.title}
                      </a>
                    </h3>
                    <p className="article-excerpt">{post.excerpt}</p>
                    <div className="article-meta">
                      <span className="article-date">{post.date}</span>
                      <span className="article-read-time">{post.readTime} read</span>
                    </div>
                  </div>
                </div>
              ))}
              
              {filteredPosts.length === 0 && (
                <div className="no-articles-message">
                  <p>No articles found in this category.</p>
                  <button className="secondary-button" onClick={() => setActiveCategory('all')}>
                    View All Articles
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        
        <div className="modal-footer">
          <p>Want to learn more about our no-code development services?</p>
          <div className="footer-buttons">
            <a href="mailto:info@cloudvirtue.com" className="tertiary-button">Contact Us</a>
            <button className="primary-button" onClick={onClose}>Close</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StayInformedModal;
