import React, { useEffect } from 'react';
import HomeHero from '../components/sections/heroes/HomeHero';
import Services from '../components/sections/Services';
import ConsultationCTA from '../components/sections/ConsultationCTA';
import About from '../components/sections/About';
import '../styles/pages/home.css';

function Home() {
  useEffect(() => {
    document.title = 'CLOUD VIRTUE | Web Development & Digital Solutions';
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home-page">
      <HomeHero />
      <Services />
      <ConsultationCTA />
      <About />
    </div>
  );
}

export default Home;
