/**
 * RSS Feed Service - Fetches and parses RSS feeds client-side
 * Uses RSS to JSON API as a CORS proxy for accessing external feeds
 */
class RssFeedService {
  constructor() {
    this.CACHE_KEY = 'rss_articles_cache';
    this.LAST_FETCH_KEY = 'rss_articles_last_fetch';
    this.CACHE_DURATION = 3600000; // 1 hour in milliseconds
    
    // RSS feed sources for no-code and AI content
    this.sources = [
      { 
        url: 'https://www.deeplearning.ai/the-batch/', 
        name: 'DeepLearning.AI', 
        category: 'ai-integrations',
        rssUrl: 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.deeplearning.ai%2Fblog%2Ffeed%2F'
      },
      { 
        url: 'https://webflow.com/blog', 
        name: 'Webflow Blog', 
        category: 'tools',
        rssUrl: 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwebflow.com%2Fblog%2Frss.xml'
      },
      { 
        url: 'https://ai.googleblog.com/', 
        name: 'Google AI', 
        category: 'ai-integrations',
        rssUrl: 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fai.googleblog.com%2Ffeeds%2Fposts%2Fdefault'
      },
      { 
        url: 'https://zapier.com/blog/', 
        name: 'Zapier Blog', 
        category: 'automations',
        rssUrl: 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fzapier.com%2Fblog%2Ffeeds%2Flatest%2F'
      },
      { 
        url: 'https://www.makerpad.co/blog', 
        name: 'Makerpad', 
        category: 'tools',
        rssUrl: 'https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.makerpad.co%2Fblog%2Frss.xml'
      }
    ];
  }

  /**
   * Fetch articles from all RSS sources
   */
  async fetchArticles() {
    try {
      // Check cache first
      const cachedData = this.getCachedArticles();
      const now = new Date().getTime();
      
      if (cachedData.articles && cachedData.articles.length > 0 && 
          cachedData.lastFetchDate && (now - cachedData.lastFetchDate) < this.CACHE_DURATION) {
        console.log('Using cached RSS data');
        return cachedData.articles;
      }
      
      console.log('Fetching fresh RSS feeds');
      // Fetch all sources in parallel
      const articlePromises = this.sources.map(source => this.fetchSource(source));
      const results = await Promise.allSettled(articlePromises);
      
      // Combine all successful results
      const articles = [];
      results.forEach(result => {
        if (result.status === 'fulfilled' && result.value) {
          articles.push(...result.value);
        }
      });
      
      // Sort by date (newest first)
      articles.sort((a, b) => new Date(b.date) - new Date(a.date));
      
      // Cache the results
      localStorage.setItem(this.CACHE_KEY, JSON.stringify(articles));
      localStorage.setItem(this.LAST_FETCH_KEY, now.toString());
      
      return articles;
    } catch (error) {
      console.error('Error fetching RSS feeds:', error);
      // Return cached data if available
      const cachedData = this.getCachedArticles();
      return cachedData.articles || [];
    }
  }
  
  /**
   * Fetch and parse a single RSS source
   */
  async fetchSource(source) {
    try {
      const response = await fetch(source.rssUrl);
      if (!response.ok) {
        throw new Error(`Failed to fetch ${source.name}: ${response.status}`);
      }
      
      const data = await response.json();
      
      if (!data.items || !Array.isArray(data.items)) {
        console.warn(`Invalid RSS response from ${source.name}`);
        return null;
      }
      
      // Debug output to see raw item data
      if (data.items.length > 0) {
        console.log(`First item from ${source.name}:`, {
          title: data.items[0].title,
          hasImage: !!this.extractImage(data.items[0]),
          imageSource: this.identifyImageSource(data.items[0])
        });
      }
      
      // Transform the RSS items into our standard article format
      return data.items.slice(0, 5).map((item, index) => {
        // Try special case extraction first for certain types of articles
        let articleImage = this.extractSpecialCaseImages(item, source);
        
        // Fall back to standard extraction if no special case image found
        if (!articleImage) {
          articleImage = this.extractImage(item);
        }
        
        // If still no image, don't assign a default - let the component handle that
        
        return {
          id: `${source.name.toLowerCase().replace(/\s/g, '-')}-${index}`,
          title: item.title,
          excerpt: this.cleanExcerpt(item.description || item.content),
          category: source.category,
          author: source.name,
          date: new Date(item.pubDate).toISOString().split('T')[0],
          readTime: this.estimateReadTime(item.description || item.content),
          image: articleImage,
          externalUrl: item.link || source.url
        };
      });
    } catch (error) {
      console.error(`Error fetching ${source.name}:`, error);
      return null;
    }
  }
  
  /**
   * Clean HTML content and extract a readable excerpt
   */
  cleanExcerpt(html) {
    // Remove HTML tags
    const text = html.replace(/<\/?[^>]+(>|$)/g, " ");
    // Remove extra spaces
    const cleaned = text.replace(/\s+/g, " ").trim();
    // Limit to first 150 characters
    return (cleaned.length > 150) ? cleaned.substring(0, 147) + '...' : cleaned;
  }
  
  /**
   * Extract featured image from RSS item
   */
  extractImage(item) {
    // Try to get the media content (highest quality usually)
    if (item.media && item.media.content && item.media.content.url) {
      return item.media.content.url;
    }
    
    // Try to get the enclosure image
    if (item.enclosure && item.enclosure.link) {
      return item.enclosure.link;
    }
    
    // Try to get media:thumbnail
    if (item.media && item.media.thumbnail && item.media.thumbnail.url) {
      return item.media.thumbnail.url;
    }
    
    // Try to get thumbnail
    if (item.thumbnail) {
      return item.thumbnail;
    }
    
    // Look for first image in content with more flexible matching
    if (item.content) {
      // Try standard img tag
      let match = item.content.match(/<img[^>]+src=["']([^"']+)["']/i);
      if (match && match[1]) {
        return match[1];
      }
      
      // Try background image in style
      match = item.content.match(/background-image:\s*url\(['"]?([^'"()]+)['"]?\)/i);
      if (match && match[1]) {
        return match[1];
      }
      
      // Try data-src (lazy loading)
      match = item.content.match(/<img[^>]+data-src=["']([^"']+)["']/i);
      if (match && match[1]) {
        return match[1];
      }
    }
    
    // Try description for an image if content failed
    if (item.description) {
      const match = item.description.match(/<img[^>]+src=["']([^"']+)["']/i);
      if (match && match[1]) {
        return match[1];
      }
    }
    
    // Look for og:image metadata
    if (item.meta && item.meta['og:image']) {
      return item.meta['og:image'];
    }
    
    // Some RSS feeds include image as a separate property
    if (item.image) {
      return typeof item.image === 'string' ? item.image : item.image.url || item.image.href || item.image.src;
    }
    
    // If we have a link, try to generate an image from Favicon API
    if (item.link) {
      try {
        const url = new URL(item.link);
        return `https://www.google.com/s2/favicons?domain=${url.hostname}&sz=128`;
      } catch (e) {
        console.warn('Failed to parse URL for favicon', e);
      }
    }
    
    return null;
  }
  
  /**
   * Add a specific handler for certain sites that need special image extraction
   * This helps with sites like Anthropic where standard extraction may fail
   */
  extractSpecialCaseImages(item, source) {
    // Special case for articles that mention Anthropic or Claude
    if (item.title && (
        item.title.toLowerCase().includes('anthropic') || 
        item.title.toLowerCase().includes('claude')
      )) {
      // Try to extract from known patterns for Anthropic articles
      if (item.content) {
        // Look for image tags with specific URL patterns
        const patterns = [
          /<img[^>]+src=["']([^"']*anthropic[^"']*)["']/i, 
          /<img[^>]+src=["']([^"']*claude[^"']*)["']/i,
          /<img[^>]+class=["']([^"']*featured[^"']*)["']/i
        ];
        
        for (const pattern of patterns) {
          const match = item.content.match(pattern);
          if (match && match[1]) {
            console.log('Found special case image for Anthropic article:', match[1]);
            return match[1];
          }
        }
      }
    }
    
    return null;
  }
  
  /**
   * Identify where an image is coming from (for debugging)
   */
  identifyImageSource(item) {
    if (item.media && item.media.content && item.media.content.url) {
      return { source: 'media.content', url: item.media.content.url };
    }
    
    if (item.enclosure && item.enclosure.link) {
      return { source: 'enclosure', url: item.enclosure.link };
    }
    
    if (item.media && item.media.thumbnail && item.media.thumbnail.url) {
      return { source: 'media.thumbnail', url: item.media.thumbnail.url };
    }
    
    if (item.thumbnail) {
      return { source: 'thumbnail', url: item.thumbnail };
    }
    
    if (item.content) {
      const match = item.content.match(/<img[^>]+src=["']([^"']+)["']/i);
      if (match && match[1]) {
        return { source: 'content_image', url: match[1] };
      }
    }
    
    if (item.description) {
      const match = item.description.match(/<img[^>]+src=["']([^"']+)["']/i);
      if (match && match[1]) {
        return { source: 'description_image', url: match[1] };
      }
    }
    
    // Continue with other checks as needed
    return { source: 'none_found', itemKeys: Object.keys(item) };
  }
  
  /**
   * Estimate read time in minutes
   */
  estimateReadTime(content) {
    // Strip HTML tags
    const text = content.replace(/<\/?[^>]+(>|$)/g, " ");
    // Count words (approx)
    const words = text.split(/\s+/).length;
    // Average reading speed: 200 words per minute
    const minutes = Math.max(1, Math.round(words / 200));
    return `${minutes} min`;
  }
  
  /**
   * Get cached articles if available
   */
  getCachedArticles() {
    try {
      const articles = JSON.parse(localStorage.getItem(this.CACHE_KEY)) || [];
      const lastFetchDate = parseInt(localStorage.getItem(this.LAST_FETCH_KEY), 10) || null;
      
      return {
        articles,
        lastFetchDate
      };
    } catch (error) {
      console.error("Error reading cached RSS articles:", error);
      return { articles: [], lastFetchDate: null };
    }
  }

  /**
   * Get the timestamp of when articles were last fetched
   * @returns {string|null} ISO timestamp or null if never fetched
   */
  getLastFetchTime() {
    const timestamp = localStorage.getItem(this.LAST_FETCH_KEY);
    return timestamp ? new Date(parseInt(timestamp, 10)) : null;
  }
}

export default new RssFeedService();
