/**
 * Configuration file for hosting facts to be displayed in the HostingHero component
 */

// Array of creative hosting facts
export const hostingFacts = [
  {
    id: 1,
    fact: "Websites hosted on high-performance servers load up to 40% faster, reducing bounce rates by an average of 25%.",
    title: "Speed Matters"
  },
  {
    id: 2,
    fact: "Nearly 85% of website security breaches occur on self-hosted or inadequately protected hosting environments.",
    title: "Security First"
  },
  {
    id: 3,
    fact: "Cloud hosting can reduce energy consumption by up to 87% compared to traditional on-premises servers.",
    title: "Eco-Friendly"
  },
  {
    id: 4,
    fact: "Businesses that migrate to scalable cloud hosting experience an average of 99.98% uptime compared to 98.5% on traditional hosting.",
    title: "Maximum Uptime"
  },
  {
    id: 5,
    fact: "Companies using managed hosting solutions save an average of 30 IT work hours per month on maintenance and security tasks.",
    title: "Resource Efficiency"
  }
];
