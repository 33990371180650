import React, { useState, useEffect } from 'react';
import ArticleService from '../../services/ArticleService';
import RssFeedService from '../../services/RssFeedService';
import { sampleArticles } from '../../data/sampleArticles';
import { getFallbackImage } from '../../config/articleConfig';
import '../../styles/modals/consultation-modal.css';
import '../../styles/modals/article-modal.css';

const ArticleModal = ({ isOpen, onClose, category = 'ai-integrations' }) => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    if (isOpen) {
      // Prevent background scrolling when modal is open
      document.body.style.overflow = 'hidden';
      
      const fetchArticles = async () => {
        setLoading(true);
        setError(null);
        
        try {
          // Fetch RSS feeds from external sources
          const rssArticles = await RssFeedService.fetchArticles();
          
          if (rssArticles && rssArticles.length > 0) {
            filterAndSetArticles(rssArticles);
          } else {
            // Try the original API as fallback
            try {
              const apiArticles = await ArticleService.forceRefresh();
              if (apiArticles && apiArticles.articles && apiArticles.articles.length > 0) {
                filterAndSetArticles(apiArticles.articles);
              } else {
                // Fall back to sample data if both methods return empty
                filterAndSetArticles(sampleArticles);
              }
            } catch (apiError) {
              console.warn('API fetch failed, using sample data:', apiError);
              filterAndSetArticles(sampleArticles);
            }
          }
        } catch (err) {
          console.error("Error loading articles:", err);
          setError("Unable to load external articles. Using sample data instead.");
          // Fall back to sample data on error
          filterAndSetArticles(sampleArticles);
        } finally {
          setLoading(false);
        }
      };
      
      fetchArticles();
    }
    
    // Re-enable scrolling when modal is closed
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen, category]);
  
  const filterAndSetArticles = (articleData) => {
    // Filter articles based on the category or AI-related keywords
    const filteredArticles = articleData.filter(article => 
      article.category === category || 
      article.title.toLowerCase().includes('ai') ||
      article.title.toLowerCase().includes('no-code') ||
      article.excerpt.toLowerCase().includes('artificial intelligence') ||
      article.excerpt.toLowerCase().includes('machine learning')
    );
    
    setArticles(filteredArticles.length > 0 ? filteredArticles : articleData.slice(0, 5));
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="article-modal consultation-modal" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose} aria-label="Close modal">×</button>
        
        <div className="article-modal-content">
          <h2 className="modal-title" style={{ color: "#111827" }}>No-Code Development Guide</h2>
          <p className="modal-subtitle">Curated articles to help you build powerful applications without writing code</p>
          
          <div className="articles-container">
            {loading ? (
              <div className="loading-spinner">Loading articles...</div>
            ) : error ? (
              <div className="error-message">{error}</div>
            ) : (
              <div className="articles-grid">
                {articles.length > 0 ? (
                  articles.map((article, index) => (
                    <div key={article.id || index} className="article-card">
                      <div className="article-image">
                        <img 
                          src={article.image || getFallbackImage(article.category)} 
                          alt={article.title} 
                          onError={(e) => {
                            console.log(`Image failed to load: ${article.title}`, article.image);
                            e.target.onerror = null;
                            e.target.src = getFallbackImage(article.category);
                          }}
                        />
                      </div>
                      <div className="article-content">
                        <h3>{article.title}</h3>
                        <p>{article.excerpt}</p>
                        <div className="article-meta">
                          <span className="article-date">{article.date || 'Recent'}</span>
                          <span className="article-read-time">{article.readTime || '5 min'} read</span>
                        </div>
                        <a href={article.externalUrl || '#'} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className="read-more-button">
                          Read More
                        </a>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="no-articles-message">
                    No articles found. Please check again later.
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleModal;
