import React, { useState } from 'react';
import '../../styles/components/forms.css';
import '../../styles/modals/careers-modal.css';

function CareersContent() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    portfolioLink: '',
    message: '',
    resume: null
  });
  
  const [submitStatus, setSubmitStatus] = useState({
    submitted: false,
    success: false,
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData(prevState => ({
      ...prevState,
      resume: file
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Create form data to handle file upload
      const formDataToSend = new FormData();
      
      // Add all form fields to the FormData
      Object.keys(formData).forEach(key => {
        if (key !== 'resume' || !formData[key]) {
          formDataToSend.append(key, formData[key]);
        }
      });
      
      // Add resume file if exists
      if (formData.resume) {
        formDataToSend.append('resume', formData.resume);
      }
      
      // For this implementation, we'll convert to JSON for consistency with other forms
      // Note: A full implementation would use FormData to handle file uploads properly
      const jsonData = {
        name: formData.name,
        email: formData.email,
        position: formData.position || 'Not specified',
        message: formData.message
      };
      
      // Send form data to the PHP handler
      const response = await fetch('/api/careers-handler.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData)
      });
      
      const result = await response.json();
      
      if (response.ok) {
        setSubmitStatus({
          submitted: true,
          success: true,
          message: result.message
        });
        
        // Reset form after submission
        setFormData({
          name: '',
          email: '',
          phone: '',
          portfolioLink: '',
          message: '',
          resume: null
        });
        
        // Reset file input if it exists
        const fileInput = document.querySelector('input[type="file"]');
        if (fileInput) fileInput.value = '';
      } else {
        setSubmitStatus({
          submitted: true,
          success: false,
          message: result.message || 'Something went wrong. Please try again.'
        });
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitStatus({
        submitted: true,
        success: false,
        message: 'Network error. Please check your connection and try again.'
      });
    }
  };

  return (
    <div className="careers-modal-content">
      {submitStatus.submitted && submitStatus.success ? (
        <div className="submission-success">
          <h3>Application Submitted!</h3>
          <p>{submitStatus.message}</p>
          <button 
            className="btn btn-primary mt-3"
            onClick={() => setSubmitStatus({ submitted: false, success: false, message: '' })}
          >
            Submit Another Application
          </button>
        </div>
      ) : (
        <>
          <p className="careers-intro">
            Interested in joining our No-Code Developer network? We're always looking for talented individuals who are passionate about no-code/low-code solutions and innovation.
            Share your information with us, and we'll be in touch about potential collaboration opportunities.
          </p>
          
          <form className="careers-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="name" className="form-label">Full Name *</label>
              <input
                type="text"
                id="name"
                name="name"
                className="form-control"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="email" className="form-label">Email Address *</label>
              <input
                type="email"
                id="email"
                name="email"
                className="form-control"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="phone" className="form-label">Phone Number</label>
              <input
                type="tel"
                id="phone"
                name="phone"
                className="form-control"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="portfolioLink" className="form-label">Portfolio/LinkedIn/GitHub URL</label>
              <input
                type="url"
                id="portfolioLink"
                name="portfolioLink"
                className="form-control"
                placeholder="https://your-portfolio.com"
                value={formData.portfolioLink}
                onChange={handleChange}
              />
            </div>
            
            <div className="form-group">
              <label htmlFor="resume" className="form-label">Resume/CV (PDF, DOCX) *</label>
              <input
                type="file"
                id="resume"
                name="resume"
                className="form-control file-input"
                accept=".pdf,.doc,.docx"
                onChange={handleFileChange}
                required
              />
              <small className="file-help-text">Maximum file size: 5MB</small>
            </div>
            
            <div className="form-group">
              <label htmlFor="message" className="form-label">Additional Information</label>
              <textarea
                id="message"
                name="message"
                className="form-control"
                rows="4"
                placeholder="Tell us about your experience, interests, or anything else you'd like to share."
                value={formData.message}
                onChange={handleChange}
              ></textarea>
            </div>
            
            <div className="form-privacy-note">
              <small>By submitting this form, you agree to our <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>. Your information will only be used to evaluate your application.</small>
            </div>
            
            <div className="form-actions">
              <button type="submit" className="btn btn-primary">Submit Application</button>
            </div>
          </form>
        </>
      )}
    </div>
  );
}

export default CareersContent;
