import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../common/Icon';
import WorksModal from '../../modals/WorksModal';
import './styles/heroes.css';

function NoCodeHero() {
  const [isWorksModalOpen, setIsWorksModalOpen] = useState(false);
  
  const openWorksModal = () => {
    setIsWorksModalOpen(true);
  };
  
  const closeWorksModal = () => {
    setIsWorksModalOpen(false);
  };

  return (
    <section className="page-hero">
      <div className="container">
        <div className="hero-content-wrapper">
          <div className="hero-text-content">
            <h1>No-Code Development <br/><span className="highlight-text">More For Less</span></h1>
            <p className="lead">Build powerful applications without writing a single line of code with CLOUD VIRTUE's low-cost no-code dev approach</p>
            <div className="hero-buttons">
              <Link to="/no-code-request" className="primary-button">
                <span>Start Building</span>
              </Link>
              <button onClick={openWorksModal} className="secondary-button">View Works</button>
            </div>
          </div>
          <div className="hero-image-content">
            <div className="featured-article-card">
              <div className="project-info">
                <div className="project-icon">
                  <div className="featured-label">Featured Project</div>
                  <a href="https://www.cloudvirtue.net" target="_blank" rel="noopener noreferrer">
                    <img src="/assets/images/projects/cloudvirtue.net.jpeg" alt="CLOUD VIRTUE No-Code Project" />
                  </a>
                </div>
                <div className="project-details">
                  <h3>AI Insights Micro-Blog</h3>
                  <p className="article-excerpt">Discover our no-code AI blog showcasing the latest trends, tools, and applications in artificial intelligence</p>
                </div>
              </div>
              <a href="https://www.cloudvirtue.net" target="_blank" rel="noopener noreferrer" className="card-link-button">
                <span className="link-text">View Project</span>
                <Icon name="arrowRight" size={20} />
              </a>
            </div>
          </div>
        </div>
      </div>
      
      {/* Works Modal */}
      <WorksModal isOpen={isWorksModalOpen} onClose={closeWorksModal} />
    </section>
  );
}

export default NoCodeHero;