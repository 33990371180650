/**
 * Date and time utility functions
 */

/**
 * Format a date to display in the format "MMM DD, h:mm A"
 * Example: "Mar 24, 11:30 AM"
 * 
 * @param {Date|string|number} date - Date object, ISO string, or timestamp
 * @returns {string} Formatted date string
 */
export const formatUpdateTime = (date) => {
  if (!date) return '';
  
  const dateObj = typeof date === 'string' || typeof date === 'number' 
    ? new Date(date) 
    : date;
    
  // Check if date is valid
  if (isNaN(dateObj.getTime())) return '';
  
  const options = { 
    month: 'short', 
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  };
  
  return dateObj.toLocaleDateString('en-US', options);
};
