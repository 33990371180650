import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { StepProvider } from './context/StepContext';

// Layout components
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';

// Page components
import Home from './pages/Home';
import Domains from './pages/Domains';
import Hosting from './pages/Hosting';
import NoCodeDev from './pages/NoCodeDev';
import GetStarted from './pages/GetStarted';
import NoCodeRequest from './pages/NoCodeRequest';
import NotFound from './pages/NotFound';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';

// Styles
import './styles/global.css';

function App() {
  return (
    <Router>
      <StepProvider>
        <div className="app">
          <Header />
          <main className="main-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/domains" element={<Domains />} />
              <Route path="/hosting" element={<Hosting />} />
              <Route path="/no-code-dev" element={<NoCodeDev />} />
              <Route path="/get-started" element={<GetStarted />} />
              <Route path="/no-code-request" element={<NoCodeRequest />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </StepProvider>
    </Router>
  );
}

export default App;
