import React from 'react';
import '../../styles/components/works-modal.css';
import '../../styles/modals/consultation-modal.css';

function WorksModal({ isOpen, onClose }) {
  if (!isOpen) return null;
  
  const openWorksTab = () => {
    window.open('https://works.cloudvirtue.com', '_blank', 'noopener,noreferrer');
  };
  
  return (
    <div className="works-modal-overlay" onClick={onClose}>
      <div className="works-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>×</button>
        
        <div className="works-redirect-content">
          <h2>CLOUD VIRTUE Works</h2>
          <p>
            The current portfolio showcases primarily web application projects, which represent only a portion of our diverse capabilities. We are developing a comprehensive portal to demonstrate our full range of services and expertise. Please check back regularly for updates as we continue to expand our showcase.
          </p>
          
          <button 
            className="works-redirect-button" 
            onClick={openWorksTab}
          >
            View Current Portfolio
          </button>
        </div>
      </div>
    </div>
  );
}

export default WorksModal;
