import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../styles/components/header.css';

function Header() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const dropdownRef = useRef(null);
  const location = useLocation();
  
  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  // Close mobile nav and dropdown when changing routes
  useEffect(() => {
    setMobileNavOpen(false);
    setDropdownOpen(false);
  }, [location]);
  
  const toggleMobileNav = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  return (
    <header className="header">
      <div className="container header-content">
        <div className="logo">
          <Link to="/">
            <img src="/assets/images/logo.png" alt="CLOUD VIRTUE" />
          </Link>
        </div>
        
        <nav className={`main-nav ${mobileNavOpen ? 'active' : ''}`}>
          <ul className="nav-links">
            <li>
              <Link to="/" className={location.pathname === '/' ? 'active' : ''}>Home</Link>
            </li>
            <li>
              <Link to="/domains" className={location.pathname === '/domains' ? 'active' : ''}>Domains</Link>
            </li>
            <li>
              <Link to="/hosting" className={location.pathname === '/hosting' ? 'active' : ''}>Hosting</Link>
            </li>
            <li>
              <Link to="/no-code-dev" className={location.pathname === '/no-code-dev' ? 'active' : ''}>No-Code Dev</Link>
            </li>
          </ul>
        </nav>
        
        <div className="header-actions">
          <Link to="/get-started" className="primary-button header-button">Get Started</Link>
          <button className={`mobile-nav-toggle ${mobileNavOpen ? 'active' : ''}`} onClick={toggleMobileNav} aria-label="Toggle navigation">
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </header>
  );
}

export default Header;
