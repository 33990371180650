import React, { useState } from 'react';
import ConsultationModal from '../modals/ConsultationModal';
import '../../styles/components/consultation-cta.css';

function ConsultationCTA() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden'; // Prevent scrolling when modal is open
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = 'auto'; // Re-enable scrolling
  };

  return (
    <section className="consultation-cta-section">
      <div className="container">
        <div className="consultation-cta-content">
          <h2>Ready to Transform Your Business?</h2>
          <p>Schedule a free consultation with our experts to discuss your needs and explore how we can help you leverage technology for growth.</p>
          <button className="primary-button" onClick={openModal}>
            Schedule a Consultation
          </button>
        </div>
        
        <ConsultationModal 
          isOpen={isModalOpen} 
          onClose={closeModal} 
        />
      </div>
    </section>
  );
}

export default ConsultationCTA;
