import React from 'react';
import '../../styles/modals/about-modal.css';

function AboutContent() {
  return (
    <div className="about-modal-content">
      <p className="company-intro">At CLOUD VIRTUE, we're more than just a technology company—we're innovators at the intersection of AI, cloud infrastructure, and business transformation. Founded in 2015, we set out with a bold vision: to democratize cutting-edge technology for businesses of all sizes.</p>
      
      <h3 className="section-title">What Sets Us Apart</h3>
      <div className="values-grid">
        <div className="value-item">
          <h4>Human-Centered Technology</h4>
          <p>We believe technology should serve people, not the other way around. Every solution we create puts your needs at the center.</p>
        </div>
        <div className="value-item">
          <h4>End-to-End Expertise</h4>
          <p>From domains to hosting to sophisticated AI implementations, we provide seamless solutions across the digital spectrum.</p>
        </div>
        <div className="value-item">
          <h4>No-Code Revolution</h4>
          <p>We're pioneering the no-code movement, making enterprise-level applications accessible without massive development teams.</p>
        </div>
        <div className="value-item">
          <h4>Partner, Not Provider</h4>
          <p>We measure our success by your outcomes. Your growth is our ultimate metric of achievement.</p>
        </div>
      </div>
      
      <div className="values-grid mission-vision-grid">
        <div className="value-item">
          <h4>Our Mission</h4>
          <p>To empower businesses with accessible, powerful technology solutions that drive genuine transformation and sustainable growth.</p>
        </div>
        <div className="value-item">
          <h4>Our Vision</h4>
          <p>A world where every organization, regardless of size, can harness the full power of AI and cloud technology to realize their unique potential.</p>
        </div>
      </div>
    </div>
  );
}

export default AboutContent;
