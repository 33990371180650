import React, { useEffect, useState } from 'react';
import '../../styles/modals/payment-modal.css';

function PaymentModal({ isOpen, onClose, amount, description, onSuccess }) {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  
  // Format amount for display
  const formattedAmount = parseFloat(amount).toFixed(2);
  
  useEffect(() => {
    // Clean up any previous PayPal script
    const cleanup = () => {
      const existingScript = document.querySelector('script[src*="paypal.com/sdk/js"]');
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
      setScriptLoaded(false);
    };
    
    // Only load the script if the modal is open
    if (isOpen) {
      cleanup(); // Clean up existing script first
      
      const script = document.createElement('script');
      // Disable credit and pay later options with disable-funding parameter
      script.src = `https://www.paypal.com/sdk/js?client-id=AZO8A77X5REGD1auzk1zfBIRZUHXY0TOCcmqP6tkp1VrK61LxdiPJHJDB4jDNRMwLlhJtAahv1VfRcmW&currency=USD&intent=capture&disable-funding=paylater`;
      script.async = true;
      
      script.onload = () => {
        setScriptLoaded(true);
        renderPayPalButtons();
      };
      
      script.onerror = (err) => {
        console.error('Error loading PayPal script:', err);
      };
      
      document.body.appendChild(script);
    }
    
    // Clean up when component unmounts or modal closes
    return cleanup;
  }, [isOpen]);
  
  const renderPayPalButtons = () => {
    if (window.paypal && document.getElementById('paypal-button-container')) {
      try {
        // Clear any existing buttons
        document.getElementById('paypal-button-container').innerHTML = '';
        
        window.paypal.Buttons({
          style: {
            layout: 'vertical',
            color: 'blue',
            shape: 'rect',
            label: 'pay',
            height: 45,
            tagline: false
          },
          
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [{
                description: description,
                amount: {
                  currency_code: 'USD',
                  value: amount
                }
              }]
            });
          },
          
          onApprove: (data, actions) => {
            setPaymentProcessing(true);
            
            return actions.order.capture().then(details => {
              setPaymentProcessing(false);
              onSuccess(details);
            });
          },
          
          onCancel: () => {
            console.log('Payment cancelled');
          },
          
          onError: (err) => {
            console.error("Payment error:", err);
            setPaymentProcessing(false);
          }
        }).render('#paypal-button-container');
      } catch (error) {
        console.error('Error rendering PayPal buttons:', error);
      }
    }
  };
  
  if (!isOpen) return null;
  
  return (
    <div className="payment-modal-overlay">
      <div className="payment-modal">
        <button className="modal-close" onClick={onClose}>×</button>
        
        <div className="payment-modal-content">
          <h2>Complete Your Purchase</h2>
          <div className="order-summary">
            <h3>Order Summary</h3>
            <div className="order-details">
              <p>{description}</p>
              <p className="order-price">${formattedAmount}</p>
            </div>
          </div>
          
          <div className="payment-methods">
            <h3>Secure Payment</h3>
            
            {paymentProcessing ? (
              <div className="processing-payment">
                <p>Processing your payment...</p>
                <div className="loading-spinner"></div>
              </div>
            ) : (
              <div className="payment-form">
                <div id="paypal-button-container"></div>
                <div className="payment-info">
                  <p>You can pay with credit card without creating a PayPal account.</p>
                </div>
              </div>
            )}
          </div>
          
          <div className="payment-disclaimer">
            <p>All payments are securely processed. No PayPal account required for credit card payments.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentModal;
