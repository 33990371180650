import React from 'react';
import '../styles/policy-pages.css';

function Terms() {
  // Scroll to top on component mount
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page-wrapper">
      <section className="container section">
        <div className="policy-content">
          <h1>Terms of Service</h1>
          <p className="last-updated">Last Updated: March 24, 2025</p>

          <div className="policy-section">
            <h2>1. Introduction</h2>
            <p>
              Welcome to CLOUD VIRTUE. These Terms of Service ("Terms") govern your use of our website located at 
              <a href="https://cloudvirtue.com"> cloudvirtue.com</a> and the services we provide, including but not limited to cloud hosting, 
              domain registration, AI integration, and no-code development solutions ("Services").
            </p>
            <p>
              By accessing our website or using our Services, you agree to be bound by these Terms and our Privacy Policy. 
              If you disagree with any part of the Terms, you may not access the website or use our Services.
            </p>
          </div>

          <div className="policy-section">
            <h2>2. Services Description</h2>
            <p>
              CLOUD VIRTUE provides various cloud-based services, including but not limited to:
            </p>
            <ul>
              <li>Cloud hosting and infrastructure management</li>
              <li>Domain name registration and management</li>
              <li>Artificial intelligence integration</li>
              <li>No-code development platforms and solutions</li>
              <li>Web and application development services</li>
              <li>Technical support and consulting</li>
            </ul>
            <p>
              The specific features and functionality of the Services are subject to change and may be updated or modified from time to time.
            </p>
          </div>

          <div className="policy-section">
            <h2>3. Account Creation and Responsibilities</h2>
            <p>
              Some of our Services require you to create an account. When you create an account, you must provide accurate, complete, and current information. 
              You are responsible for safeguarding the password and for all activities that occur under your account.
            </p>
            <p>
              You agree to:
            </p>
            <ul>
              <li>Create only one account (unless explicitly permitted)</li>
              <li>Not share your account details with others</li>
              <li>Notify us immediately of any unauthorized use of your account</li>
              <li>Ensure that you log out from your account at the end of each session</li>
              <li>Take responsibility for all activities that occur under your account</li>
            </ul>
          </div>

          <div className="policy-section">
            <h2>4. Subscription Plans and Payment</h2>
            <h3>4.1 Subscription Plans</h3>
            <p>
              We offer various subscription plans for our Services. The features, limitations, and pricing of each plan are described on our website. 
              We reserve the right to modify, terminate, or otherwise amend our subscription plans at any time.
            </p>

            <h3>4.2 Payment Terms</h3>
            <p>
              You agree to pay all fees associated with your chosen subscription plan. Payments are due in advance and may be charged on a monthly, 
              quarterly, annual, or other recurring basis depending on the subscription plan you select.
            </p>
            <p>
              If you fail to make a payment, we may suspend or terminate your access to the Services. Fees paid are non-refundable except as expressly 
              provided in these Terms or when required by law.
            </p>

            <h3>4.3 Taxes</h3>
            <p>
              All fees are exclusive of taxes, which may be added to the fees charged to you, unless you provide us with a valid tax exemption certificate.
            </p>
          </div>

          <div className="policy-section">
            <h2>5. Service Level Agreements (SLAs)</h2>
            <p>
              For certain Services, we may offer Service Level Agreements that specify performance standards and remedies for failure to meet those standards. 
              The terms of any applicable SLA will be provided separately and form part of these Terms.
            </p>
          </div>

          <div className="policy-section">
            <h2>6. Acceptable Use</h2>
            <p>
              You agree not to use our Services to:
            </p>
            <ul>
              <li>Violate any applicable law or regulation</li>
              <li>Infringe upon the rights of others</li>
              <li>Distribute malware, viruses, or other harmful code</li>
              <li>Engage in activities that interfere with or disrupt our Services</li>
              <li>Access or attempt to access accounts or systems without authorization</li>
              <li>Collect or harvest data from our Services without permission</li>
              <li>Engage in any activity that places an unreasonable load on our infrastructure</li>
              <li>Violate any acceptable use policies we may publish from time to time</li>
            </ul>
          </div>

          <div className="policy-section">
            <h2>7. Content Ownership and Licenses</h2>
            <h3>7.1 Your Content</h3>
            <p>
              You retain ownership of any intellectual property rights you hold in the content you submit, upload, or otherwise provide through our Services 
              ("Your Content"). By uploading Your Content, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, 
              and distribute Your Content for the purposes of providing and improving our Services.
            </p>

            <h3>7.2 Our Content</h3>
            <p>
              Our Services and all content provided by us, including but not limited to text, graphics, logos, icons, images, audio clips, digital downloads, 
              and software, are our property or the property of our licensors and are protected by copyright, trademark, and other intellectual property laws. 
              You may not use, reproduce, distribute, modify, or create derivative works from our content without our express written permission.
            </p>
          </div>

          <div className="policy-section">
            <h2>8. Term and Termination</h2>
            <p>
              These Terms will remain in effect until terminated by either you or us.
            </p>
            <p>
              You may terminate these Terms at any time by canceling your account and ceasing to use our Services. We may terminate or suspend your access 
              to our Services immediately, without prior notice, if you breach any provision of these Terms.
            </p>
            <p>
              Upon termination, your right to use the Services will immediately cease. All provisions of the Terms which by their nature should survive 
              termination shall survive, including without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
            </p>
          </div>

          <div className="policy-section">
            <h2>9. Disclaimer of Warranties</h2>
            <p>
              Our Services are provided "as is" and "as available" without any warranty of any kind, express or implied. 
              To the maximum extent permitted by law, we disclaim all warranties, including but not limited to implied warranties 
              of merchantability, fitness for a particular purpose, title, and non-infringement.
            </p>
            <p>
              We do not warrant that the Services will be uninterrupted, timely, secure, or error-free, or that any defects will be corrected. 
              We do not make any representations regarding the use or the results of the use of the Services in terms of their correctness, accuracy, 
              reliability, or otherwise.
            </p>
          </div>

          <div className="policy-section">
            <h2>10. Limitation of Liability</h2>
            <p>
              To the maximum extent permitted by law, in no event shall CLOUD VIRTUE, its directors, employees, partners, agents, suppliers, 
              or affiliates be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, 
              loss of profits, data, use, goodwill, or other intangible losses, resulting from:
            </p>
            <ol type="a">
              <li>Your access to or use of or inability to access or use the Services;</li>
              <li>Any conduct or content of any third party on the Services;</li>
              <li>Any content obtained from the Services; and</li>
              <li>Unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence), 
              or any other legal theory, whether or not we have been informed of the possibility of such damage.</li>
            </ol>
            <p>
              In no event will our total liability to you for all claims, damages, losses, and causes of action exceed the amount you have paid to 
              CLOUD VIRTUE for the Services in the twelve (12) months preceding the event giving rise to the claim, or one hundred U.S. dollars ($100.00), 
              whichever is greater.
            </p>
          </div>

          <div className="policy-section">
            <h2>11. Indemnification</h2>
            <p>
              You agree to defend, indemnify, and hold harmless CLOUD VIRTUE, its officers, directors, employees, and agents, from and against any claims, 
              liabilities, damages, losses, and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with:
            </p>
            <ol type="a">
              <li>Your access to or use of the Services;</li>
              <li>Your violation of these Terms;</li>
              <li>Your violation of any rights of another; or</li>
              <li>Your conduct in connection with the Services.</li>
            </ol>
          </div>

          <div className="policy-section">
            <h2>12. Governing Law and Jurisdiction</h2>
            <p>
              These Terms shall be governed by and construed in accordance with the laws of the United States, without regard to its conflict of law provisions. 
              Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.
            </p>
            <p>
              Any disputes arising out of or relating to these Terms or the Services shall be resolved exclusively in the federal or state courts located in 
              the State of [YOUR STATE], and you consent to the personal jurisdiction of such courts.
            </p>
          </div>

          <div className="policy-section">
            <h2>13. Changes to Terms</h2>
            <p>
              We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 
              30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
            </p>
            <p>
              By continuing to access or use our Services after any revisions become effective, you agree to be bound by the revised terms. 
              If you do not agree to the new terms, you are no longer authorized to use the Services.
            </p>
          </div>

          <div className="policy-section">
            <h2>14. Entire Agreement</h2>
            <p>
              These Terms constitute the entire agreement between you and CLOUD VIRTUE regarding our Services, and supersede and replace any prior 
              agreements we might have had between us regarding the Services.
            </p>
          </div>

          <div className="policy-section">
            <h2>15. Contact Information</h2>
            <p>
              If you have any questions about these Terms, please contact us at:
            </p>
            <p className="contact-info">
              CLOUD VIRTUE<br />
              Email: <a href="mailto:contact@cloudvirtue.com">contact@cloudvirtue.com</a><br />
              Website: <a href="https://cloudvirtue.com">cloudvirtue.com</a>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Terms;
