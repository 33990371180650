import React, { useState, useEffect, useRef } from 'react';
import { useStep } from '../context/StepContext';
import ConsultationModal from '../components/modals/ConsultationModal';
import '../styles/pages/no-code-request.css';

function NoCodeRequest() {
  const [currentStep, setCurrentStep] = useState(0);
  const { setCurrentStep: setGlobalStep } = useStep();
  const [projectType, setProjectType] = useState(null);
  const [timeline, setTimeline] = useState(null);
  const [budget, setBudget] = useState(null);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [integrations, setIntegrations] = useState(null);
  const [integrationsDetails, setIntegrationsDetails] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [contactMethod, setContactMethod] = useState('email');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  // Section refs for auto-scrolling
  const timelineRef = useRef(null);
  const budgetRef = useRef(null);
  const featuresRef = useRef(null);
  const integrationsRef = useRef(null);
  const contactRef = useRef(null);

  // Track form completion at each step
  const [step0Progress, setStep0Progress] = useState({
    projectType: false,
    timeline: false,
    budget: false
  });
  
  // Project type options
  const projectTypes = [
    {
      id: 'saas',
      name: 'SaaS Application',
      description: 'Subscription-based software service'
    },
    {
      id: 'webapp',
      name: 'Web Application',
      description: 'Browser-based tool or platform'
    },
    {
      id: 'ecommerce',
      name: 'E-commerce/Store',
      description: 'Online shop or marketplace'
    },
    {
      id: 'business',
      name: 'Internal Business Tool',
      description: 'For company operations'
    },
    {
      id: 'portal',
      name: 'Client Portal',
      description: 'Customer/client access point'
    },
    {
      id: 'content',
      name: 'Content Platform',
      description: 'Blog, media, community'
    },
    {
      id: 'mobile',
      name: 'Mobile App',
      description: 'iOS/Android companion app'
    }
  ];
  
  // Timeline options
  const timelineOptions = [
    { id: 'urgent', name: 'ASAP (1-2 weeks)' },
    { id: 'standard', name: 'Standard (1-2 months)' },
    { id: 'relaxed', name: 'Flexible (3+ months)' },
    { id: 'planning', name: 'Just planning for now' }
  ];
  
  // Budget ranges
  const budgetRanges = [
    { id: 'small', name: 'Under $5,000' },
    { id: 'medium', name: '$5,000 - $15,000' },
    { id: 'large', name: '$15,000 - $30,000' },
    { id: 'enterprise', name: '$30,000+' },
    { id: 'undecided', name: 'Not sure yet' }
  ];
  
  // Common features list
  const commonFeatures = [
    { id: 'auth', name: 'User Authentication/Login' },
    { id: 'payments', name: 'Payment Processing' },
    { id: 'data', name: 'Database/Data Storage' },
    { id: 'reports', name: 'Reporting/Analytics' },
    { id: 'admin', name: 'Admin Dashboard' },
    { id: 'api', name: 'API Integration' },
    { id: 'mobile', name: 'Mobile Responsiveness' },
    { id: 'search', name: 'Search Functionality' }
  ];
  
  // Contact method options
  const contactMethods = [
    { id: 'email', name: 'Email' },
    { id: 'phone', name: 'Phone' },
    { id: 'both', name: 'Both Email and Phone' }
  ];
  
  // Update progress state when selections change
  useEffect(() => {
    setStep0Progress({
      projectType: projectType !== null,
      timeline: timeline !== null,
      budget: budget !== null
    });
  }, [projectType, timeline, budget]);

  // Modal handlers
  const openModal = () => {
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Handler for setting project type and auto-scrolling
  const handleProjectTypeSelect = (typeId) => {
    setProjectType(typeId);
    if (timelineRef.current) {
      setTimeout(() => {
        timelineRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }
  };
  
  // Handler for setting timeline and auto-scrolling
  const handleTimelineSelect = (timelineId) => {
    setTimeline(timelineId);
    if (budgetRef.current) {
      setTimeout(() => {
        budgetRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 200);
    }
  };
  
  // Handler for setting budget and auto-scrolling
  const handleBudgetSelect = (budgetId) => {
    setBudget(budgetId);
  };
  
  // Handler for toggling feature and auto-scrolling
  const toggleFeature = (featureId) => {
    if (selectedFeatures.includes(featureId)) {
      setSelectedFeatures(selectedFeatures.filter(id => id !== featureId));
    } else {
      setSelectedFeatures([...selectedFeatures, featureId]);
      if (selectedFeatures.length === 0 && integrationsRef.current) {
        setTimeout(() => {
          integrationsRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 200);
      }
    }
  };
  
  // Handler for setting integrations and auto-scrolling
  const handleIntegrationsSelect = (value) => {
    setIntegrations(value);
  };
  
  // Handle "next" step depending on form state
  const handleAutoNext = () => {
    if (currentStep === 0 && budget !== null) {
      setTimeout(() => {
        nextStep();
      }, 300);
    } else if (currentStep === 1 && integrations === false) {
      setTimeout(() => {
        nextStep();
      }, 300);
    }
  };

  // Update auto-next behavior when relevant values change
  useEffect(() => {
    handleAutoNext();
  }, [budget, integrations, currentStep]);

  // Direct navigation - completely reliable method
  const handleBackButton = () => {
    // Log the current step before navigation
    console.log("Back button clicked, current step:", currentStep);
    
    // Step 2 → Step 1
    if (currentStep === 2) {
      const newStep = 1;
      setCurrentStep(newStep);
      setGlobalStep(newStep);
      
      setTimeout(() => {
        window.scrollTo(0, 0);
        console.log("Navigation complete to step:", newStep);
      }, 50);
      return;
    }
    
    // Step 1 → Step 0
    if (currentStep === 1) {
      const newStep = 0;
      setCurrentStep(newStep);
      setGlobalStep(newStep);
      
      setTimeout(() => {
        window.scrollTo(0, 0);
        console.log("Navigation complete to step:", newStep);
      }, 50);
      return;
    }
    
    // If in step 0, handle sub-sections
    if (currentStep === 0) {
      console.log("In step 0, checking selections");
      
      if (budget !== null) {
        console.log("Clearing budget selection");
        setBudget(null);
        if (timelineRef.current) {
          setTimeout(() => {
            timelineRef.current.scrollIntoView({ behavior: 'smooth' });
          }, 50);
        }
      } else if (timeline !== null) {
        console.log("Clearing timeline selection");
        setTimeline(null);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      } else if (projectType !== null) {
        console.log("Clearing project type selection");
        setProjectType(null);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  };

  // Navigation functions 
  const nextStep = () => {
    console.log("Moving to next step from:", currentStep);
    const newStep = currentStep + 1;
    setCurrentStep(newStep);
    setGlobalStep(newStep);
    
    // Ensure scroll happens after state update
    setTimeout(() => {
      window.scrollTo(0, 0);
      console.log("Navigation complete to step:", newStep);
    }, 50);
  };
  
  const prevStep = () => {
    console.log("Moving to previous step from:", currentStep);
    const newStep = currentStep - 1;
    setCurrentStep(newStep);
    setGlobalStep(newStep);
    
    // Ensure scroll happens after state update
    setTimeout(() => {
      window.scrollTo(0, 0);
      console.log("Navigation complete to step:", newStep);
    }, 50);
  };

  // Handler for final submission
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    // Simulate API call to submit data
    setTimeout(() => {
      setIsSubmitting(false);
      setIsSubmitted(true);
      
      // Clear form fields if needed
      // Reset the state here if you want to clear the form
      
      // For this demo, we'll just show the success message
      console.log('Form submitted:', {
        projectType,
        timeline,
        budget,
        selectedFeatures,
        integrations,
        integrationsDetails,
        name,
        email,
        phone,
        contactMethod
      });
    }, 1500);
  };
  
  // Cleanup on component unmount and scroll to top when mounted
  useEffect(() => {
    document.title = 'Request No-Code Dev Services | CLOUD VIRTUE';
    setGlobalStep(currentStep);
    
    // Scroll to top when component mounts
    window.scrollTo(0, 0);
    
    return () => {
      setGlobalStep(null); // Reset global step when component unmounts
    };
  }, [setGlobalStep]);
  
  // Update global step when local step changes
  useEffect(() => {
    setGlobalStep(currentStep);
  }, [currentStep, setGlobalStep]);

  // Get button text for each step
  const getButtonText = () => {
    const totalSteps = 3;
    const nextStepNum = currentStep + 2; // +2 because currentStep is 0-based and we want to show next step
    
    if (currentStep === 2) { // Last step is index 2
      return 'Submit Request';
    }
    
    return `Continue to Step ${nextStepNum} of ${totalSteps}`;
  };
  
  // Check if current step is valid to proceed
  const isStepValid = () => {
    switch (currentStep) {
      case 0: // Project basics
        return projectType && timeline && budget;
      case 1: // Requirements
        return true; // All fields are optional in this step
      case 2: // Contact info
        return name && email && (contactMethod !== 'phone' || phone);
      default:
        return false;
    }
  };
  
  // Steps content
  const steps = [
    // Step 0: Project Basics
    {
      title: 'Project Basics',
      timeEstimate: '~2 minutes',
      content: (
        <div className="step-content project-basics-step">
          <h2>Tell us about your project</h2>
          <p className="time-estimate">Time to complete: ~2 minutes</p>
          
          <div className="form-section">
            <h3>What type of project are you looking to build?</h3>
            <div className="option-cards project-type-cards">
              {projectTypes.map(type => (
                <div 
                  key={type.id}
                  className={`option-card ${projectType === type.id ? 'selected' : ''}`}
                  onClick={() => handleProjectTypeSelect(type.id)}
                >
                  <h4>{type.name}</h4>
                  <p>{type.description}</p>
                </div>
              ))}
            </div>
          </div>
          
          <div className="form-section" ref={timelineRef}>
            <h3>What's your timeline for this project?</h3>
            <div className="option-cards timeline-cards">
              {timelineOptions.map(option => (
                <div 
                  key={option.id}
                  className={`option-card ${timeline === option.id ? 'selected' : ''}`}
                  onClick={() => handleTimelineSelect(option.id)}
                >
                  <h4>{option.name}</h4>
                </div>
              ))}
            </div>
          </div>
          
          <div className="form-section" ref={budgetRef}>
            <h3>What's your approximate budget?</h3>
            <div className="option-cards budget-cards">
              {budgetRanges.map(range => (
                <div 
                  key={range.id}
                  className={`option-card ${budget === range.id ? 'selected' : ''}`}
                  onClick={() => handleBudgetSelect(range.id)}
                >
                  <h4>{range.name}</h4>
                </div>
              ))}
            </div>
          </div>
          
          <div className="step-actions">
            <button 
              className="primary-button" 
              onClick={nextStep} 
              disabled={!isStepValid()}
            >
              {getButtonText()}
            </button>
          </div>
        </div>
      )
    },
    
    // Step 1: Requirements
    {
      title: 'Key Requirements',
      timeEstimate: '~3 minutes',
      content: (
        <div className="step-content requirements-step">
          <h2>What are your key requirements?</h2>
          <p className="time-estimate">Time to complete: ~3 minutes</p>
          
          <div className="form-section" ref={featuresRef}>
            <h3>Which features are important to you?</h3>
            <div className="features-grid">
              {commonFeatures.map(feature => (
                <div 
                  key={feature.id}
                  className={`feature-checkbox ${selectedFeatures.includes(feature.id) ? 'selected' : ''}`}
                  onClick={() => toggleFeature(feature.id)}
                >
                  <span className="checkbox-indicator"></span>
                  <span className="feature-name">{feature.name}</span>
                </div>
              ))}
            </div>
          </div>
          
          <div className="form-section" ref={integrationsRef}>
            <h3>Do you need integration with existing tools or systems?</h3>
            <div className="radio-options">
              <div 
                className={`radio-option ${integrations === true ? 'selected' : ''}`}
                onClick={() => handleIntegrationsSelect(true)}
              >
                <span className="radio-indicator"></span>
                <span>Yes</span>
              </div>
              <div 
                className={`radio-option ${integrations === false ? 'selected' : ''}`}
                onClick={() => handleIntegrationsSelect(false)}
              >
                <span className="radio-indicator"></span>
                <span>No</span>
              </div>
            </div>
            
            {integrations === true && (
              <div className="text-input-field">
                <label htmlFor="integrationsDetails">Please briefly describe which systems:</label>
                <textarea
                  id="integrationsDetails"
                  value={integrationsDetails}
                  onChange={(e) => setIntegrationsDetails(e.target.value)}
                  placeholder="E.g., Shopify, QuickBooks, Salesforce, etc."
                  rows={3}
                ></textarea>
              </div>
            )}
          </div>
          
          <div className="step-actions">
            <button className="secondary-button" onClick={handleBackButton}>
              Back
            </button>
            <button 
              className="primary-button" 
              onClick={nextStep}
            >
              {getButtonText()}
            </button>
          </div>
        </div>
      )
    },
    
    // Step 2: Contact Information
    {
      title: 'Contact Information',
      timeEstimate: '~2 minutes',
      content: (
        <div className="step-content contact-step">
          <h2>How can we reach you?</h2>
          <p className="time-estimate">Time to complete: ~2 minutes</p>
          
          <div className="form-section" ref={contactRef}>
            <div className="text-input-field">
              <label htmlFor="name">Your Name*</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Full Name"
                required
              />
            </div>
            
            <div className="text-input-field">
              <label htmlFor="email">Email Address*</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email@example.com"
                required
              />
            </div>
            
            <div className="text-input-field">
              <label htmlFor="phone">Phone Number (optional)</label>
              <input
                type="tel"
                id="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="(123) 456-7890"
              />
            </div>
            
            <div className="form-section">
              <h3>Preferred contact method</h3>
              <div className="radio-options">
                {contactMethods.map(method => (
                  <div 
                    key={method.id}
                    className={`radio-option ${contactMethod === method.id ? 'selected' : ''}`}
                    onClick={() => setContactMethod(method.id)}
                  >
                    <span className="radio-indicator"></span>
                    <span>{method.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          
          <div className="step-actions">
            <button className="secondary-button" onClick={handleBackButton}>
              Back
            </button>
            <button 
              className="primary-button" 
              onClick={handleSubmit}
              disabled={!isStepValid() || isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : getButtonText()}
            </button>
          </div>
        </div>
      )
    }
  ];
  
  return (
    <div className="no-code-request-page">
      {/* Hero section */}
      <div className="request-hero">
        <div className="container">
          <h1>No-Code Development Services</h1>
          <p>Tell us about your project, and we'll help bring it to life</p>
        </div>
      </div>
      
      {/* Progress indicator */}
      <div className="progress-indicator">
        <div className="container">
          <div className="progress-steps">
            {["Project Basics", "Key Requirements", "Contact Information"].map((stepName, index) => (
              <div 
                key={index} 
                className={`progress-step ${currentStep === index ? 'active' : ''} ${currentStep > index ? 'completed' : ''}`}
              >
                <div className="step-number">{index + 1}</div>
                <div className="step-name">{stepName}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      
      {/* Form container */}
      <div className="form-container">
        <div className="container">
          {!isSubmitted ? (
            <>
              {steps[currentStep].content}
            </>
          ) : (
            <div className="submission-success">
              <h2>Thank You for Your Request!</h2>
              <p>We've received your project details and will contact you soon.</p>
              <div className="success-actions">
                <button className="primary-button" onClick={openModal}>
                  Schedule a Consultation
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      
      {/* Consultation modal */}
      <ConsultationModal 
        isOpen={isModalOpen} 
        onClose={closeModal} 
      />
    </div>
  );
}

export default NoCodeRequest;
