import React from 'react';
import {
  // Main service icons
  Brain,
  Code,
  Globe,
  Cloud,
  
  // Feature icons
  Robot,
  Lightning,
  ChartLine,
  Users,
  Gear,
  
  // UI icons
  ArrowRight,
  CaretRight,
  X,
  Plus,
  Minus,
  Check,
  WarningCircle,
  Info,
  Question,
  MagnifyingGlass,
  
  // Additional service icons
  Lock,
  Database,
  Wrench,
  
  // Social icons
  LinkedinLogo,
  TwitterLogo,
  FacebookLogo,
  
  // Feature icons
  Clock,
  ChatCircle,
  ShieldCheck,
  ArrowsClockwise,
  Globe as GlobeNetwork,
  ArrowsOut,
  
  // Value icons
  Lightbulb,
  Handshake,
  Star,
  UsersThree,
  
  // Additional UI icons
  CaretDown,
  List,
  House,
  Phone,
  Envelope,
  MapPin,
} from '@phosphor-icons/react';

// Map of icon names to Phosphor components
const ICON_MAP = {
  // Main service icons
  ai: Brain,
  nocode: Code,
  domain: Globe,
  hosting: Cloud,
  
  // Feature icons
  automation: Robot,
  workflow: Lightning,
  insight: ChartLine,
  customer: Users,
  integration: Gear,
  
  // UI icons
  arrowRight: ArrowRight,
  caretRight: CaretRight,
  close: X,
  plus: Plus,
  minus: Minus,
  check: Check,
  warning: WarningCircle,
  info: Info,
  question: Question,
  search: MagnifyingGlass,
  
  // Additional service icons
  security: Lock,
  database: Database,
  support: Wrench,
  
  // Social icons
  linkedin: LinkedinLogo,
  twitter: TwitterLogo,
  facebook: FacebookLogo,
  
  // Feature icons
  uptime: Clock,
  chat: ChatCircle,
  shield: ShieldCheck,
  backup: ArrowsClockwise,
  cdn: GlobeNetwork,
  migration: ArrowsOut,
  
  // Value icons
  innovation: Lightbulb,
  integrity: Handshake,
  excellence: Star,
  collaboration: UsersThree,
  
  // Additional UI icons
  caretDown: CaretDown,
  menu: List,
  home: House,
  phone: Phone,
  email: Envelope,
  location: MapPin,
};

/**
 * Universal icon component that uses Phosphor icons
 * @param {string} name - The name of the icon from ICON_MAP
 * @param {string} size - Size in pixels (default: 24)
 * @param {string} weight - Icon weight: 'thin', 'light', 'regular', 'bold', 'fill', 'duotone' (default: 'regular')
 * @param {string} color - Icon color (default: 'currentColor')
 */
const Icon = ({ name, size = 24, weight = 'regular', color = 'currentColor', ...props }) => {
  const IconComponent = ICON_MAP[name];
  
  if (!IconComponent) {
    console.warn(`Icon "${name}" not found in icon map`);
    return null;
  }
  
  return (
    <IconComponent
      size={size}
      weight={weight}
      color={color}
      {...props}
    />
  );
};

export default Icon; 