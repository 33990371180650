// Sample articles for the Stay Informed modal - with real links to external content
export const sampleArticles = [
  {
    id: 1,
    title: 'The Future of Generative AI in Business',
    excerpt: 'How generative AI is transforming content creation, product design, and customer interactions across industries.',
    category: 'ai-integrations',
    author: 'DeepLearning.AI',
    date: '2023-10-15',
    readTime: '5 min',
    image: '/assets/images/ai/tech-integration.jpg',
    externalUrl: 'https://www.deeplearning.ai/the-batch/'
  },
  {
    id: 2,
    title: 'Building Powerful Apps Without Code: 2023 Guide',
    excerpt: 'How no-code platforms are revolutionizing app development and making it accessible to non-technical entrepreneurs.',
    category: 'tools',
    author: 'Webflow Blog',
    date: '2023-10-12',
    readTime: '6 min',
    image: '/assets/images/illustrations/dns-http-dev.png',
    externalUrl: 'https://webflow.com/blog'
  },
  {
    id: 3,
    title: 'Practical Applications of NLP in Customer Service',
    excerpt: 'Implementing natural language processing to automate and enhance customer service operations.',
    category: 'ai-integrations',
    author: 'Google AI',
    date: '2023-10-10',
    readTime: '7 min',
    image: '/assets/images/ai/case-study1.jpg',
    externalUrl: 'https://ai.googleblog.com/'
  },
  {
    id: 4,
    title: 'Integrating AI with No-Code: The Power of Modern Development',
    excerpt: 'Explore how AI and no-code platforms combine to create a new paradigm for building advanced applications without traditional coding.',
    category: 'ai-integrations',
    author: 'MIT Technology Review',
    date: '2023-10-08',
    readTime: '8 min',
    image: '/assets/images/ai/case-study2.jpg',
    externalUrl: 'https://www.technologyreview.com/topic/artificial-intelligence/'
  },
  {
    id: 5,
    title: 'Automating Business Processes Without IT Support',
    excerpt: 'Step-by-step guide for non-technical teams to automate workflows and increase productivity using no-code tools.',
    category: 'automations',
    author: 'Zapier Blog',
    date: '2023-10-05',
    readTime: '6 min',
    image: '/assets/images/ai/case-study3.jpg',
    externalUrl: 'https://zapier.com/blog/'
  },
  {
    id: 6,
    title: 'Getting Started with Machine Learning: A Beginner\'s Guide',
    excerpt: 'Essential concepts and tools for those looking to dive into the world of machine learning.',
    category: 'ai-integrations',
    author: 'Sarah Johnson',
    date: '2023-10-03',
    readTime: '10 min',
    image: '/assets/images/ai/case-study1.jpg',
    externalUrl: '#'
  },
  {
    id: 7,
    title: 'Comparing Top No-Code Development Platforms',
    excerpt: 'An in-depth analysis of leading no-code platforms to help you choose the right one for your project needs.',
    category: 'platforms',
    author: 'Sam Johnson',
    date: '2023-09-30',
    readTime: '10 min',
    image: '/assets/images/illustrations/dns-http-dev.png',
    externalUrl: '#'
  },
  {
    id: 8,
    title: 'Computer Vision Applications in Retail',
    excerpt: 'How retailers are using computer vision to transform shopping experiences and operations.',
    category: 'ai-integrations',
    author: 'David Wilson',
    date: '2023-09-28',
    readTime: '6 min',
    image: '/assets/images/ai/case-study2.jpg',
    externalUrl: '#'
  },
  {
    id: 9,
    title: 'From Idea to Launch: A No-Code Success Story',
    excerpt: 'How a startup went from concept to market in just 3 weeks using no-code tools - a detailed case study.',
    category: 'case-studies',
    author: 'Jamie Rodriguez',
    date: '2023-09-25',
    readTime: '9 min',
    image: '/assets/images/ai/case-study3.jpg',
    externalUrl: '#'
  },
  {
    id: 10,
    title: 'ROI of AI Implementation: Case Studies',
    excerpt: 'Real-world examples of businesses that have successfully measured and maximized their AI investments.',
    category: 'case-studies',
    author: 'Alexandra Lee',
    date: '2023-09-22',
    readTime: '8 min',
    image: '/assets/images/ai/tech-integration.jpg',
    externalUrl: '#'
  },
  {
    id: 11,
    title: 'The Ethics of AI: Navigating the Gray Areas',
    excerpt: 'Examining the ethical considerations every organization should address when implementing AI solutions.',
    category: 'ai-integrations',
    author: 'Robert Martinez',
    date: '2023-09-20',
    readTime: '9 min',
    image: '/assets/images/illustrations/dns-http-dev.png',
    externalUrl: '#'
  },
  {
    id: 12,
    title: 'The Future of No-Code: Predictions for 2024',
    excerpt: 'Industry experts weigh in on how no-code development will evolve and transform businesses in the coming year.',
    category: 'platforms',
    author: 'Casey Williams',
    date: '2023-09-18',
    readTime: '5 min',
    image: '/assets/images/ai/case-study1.jpg',
    externalUrl: '#'
  }
];