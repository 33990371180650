import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/global.css';

// Make sure this matches the element in your public/index.html file
const rootElement = document.getElementById('root');

// Check if the root element exists
if (!rootElement) {
  console.error("Could not find root element to mount React application");
} else {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}
