import React, { useState, useEffect } from 'react';
import { useStep } from '../context/StepContext';
import PaymentModal from '../components/modals/PaymentModal';
import '../styles/pages/get-started.css';

function GetStarted() {
  const [currentStep, setCurrentStep] = useState(0);
  const { setCurrentStep: setGlobalStep } = useStep();
  const [userType, setUserType] = useState(null); // 'new' or 'existing'
  const [hasDomain, setHasDomain] = useState(null); // true or false
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [domainName, setDomainName] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [domainRegistered, setDomainRegistered] = useState(false);
  
  // Payment modal state
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  
  // Plans data
  const hostingPlans = [
    {
      id: 'starter',
      name: 'Starter',
      price: 5.99,
      storage: '10 GB',
      bandwidth: 'Unmetered',
      websites: 1,
      features: ['Free SSL Certificate', 'Daily Backups', '24/7 Support']
    },
    {
      id: 'business',
      name: 'Business',
      popular: true,
      price: 12.99,
      storage: '50 GB',
      bandwidth: 'Unmetered',
      websites: 5,
      features: ['Free SSL Certificate', 'Daily Backups', '24/7 Support', 'Free Domain for 1 Year', 'Performance Boost']
    },
    {
      id: 'enterprise',
      name: 'Enterprise',
      price: 24.99,
      storage: '100 GB',
      bandwidth: 'Unmetered',
      websites: 'Unlimited',
      features: ['Free SSL Certificate', 'Daily Backups', '24/7 Support', 'Free Domain for 1 Year', 'Performance Boost', 'Dedicated Resources', 'Priority Support']
    }
  ];
  
  // Handle domain modal
  const openDomainModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden';
  };
  
  const closeDomainModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = 'auto';
    
    // Always proceed to the next step when closing the modal if we're at the domain step
    // and the user selected "No, I need a domain"
    if (currentStep === 1 && hasDomain === false) {
      setCurrentStep(prev => prev + 1);
    }
  };
  
  // This would be called when domain registration is completed
  const handleDomainRegistered = (domain) => {
    setDomainName(domain);
    setDomainRegistered(true);
  };
  
  // Navigation functions 
  const nextStep = () => {
    // Special logic for the domain step (step 1) 
    if (currentStep === 1 && hasDomain === false) { 
      openDomainModal(); 
      return; 
    }
    const newStep = currentStep + 1;
    setCurrentStep(newStep);
    setGlobalStep(newStep);
  };
  
  const prevStep = () => {
    const newStep = currentStep - 1;
    setCurrentStep(newStep);
    setGlobalStep(newStep);
  };
  
  // Cleanup on component unmount and scroll to top when mounted
  useEffect(() => {
    document.title = 'Get Started | CLOUD VIRTUE';
    setGlobalStep(currentStep);
    
    // Add this line to scroll to top when component mounts
    window.scrollTo(0, 0);
    
    return () => {
      document.body.style.overflow = 'auto';
      setGlobalStep(null); // Reset global step when component unmounts
    };
  }, [setGlobalStep]);
  
  // Update global step when local step changes
  useEffect(() => {
    setGlobalStep(currentStep);
  }, [currentStep, setGlobalStep]);

  // Define a simple function to get button text without using steps
  const getButtonText = () => {
    const totalSteps = 5; // Hardcode the total steps
    const nextStepNum = currentStep + 2; // +2 because currentStep is 0-based and we want to show next step
    
    if (currentStep === 4) { // Last step is index 4
      return 'Proceed to Checkout';
    }
    
    return `Continue to Step ${nextStepNum} of ${totalSteps}`;
  };
  
  // Handle successful payment
  const handlePaymentSuccess = (details) => {
    console.log('Payment completed successfully', details);
    setPaymentCompleted(true);
    setIsPaymentModalOpen(false);
    // Here you would typically redirect to a success page or show a success message
    alert('Payment completed successfully! Order ID: ' + details.id);
  };

  // Function to get the selected plan price
  const getSelectedPlanPrice = () => {
    if (!selectedPlan) return 0;
    const plan = hostingPlans.find(p => p.id === selectedPlan);
    return plan ? plan.price : 0;
  };

  // Function to get plan description for checkout
  const getPlanDescription = () => {
    if (!selectedPlan) return '';
    const plan = hostingPlans.find(p => p.id === selectedPlan);
    return `${plan.name} Hosting Plan ${domainName ? `with domain: ${domainName}` : ''}`;
  };

  // Steps content - Define with the already created getButtonText function
  const steps = [
    // Step 0: Choose customer type
    {
      title: 'Welcome to CLOUD VIRTUE',
      content: (
        <div className="step-content user-type-step">
          <h2>Let's get started with your website</h2>
          <p>Tell us a bit about yourself so we can better assist you:</p>
          
          <div className="option-cards">
            <div 
              className={`option-card ${userType === 'existing' ? 'selected' : ''}`}
              onClick={() => setUserType('existing')}
            >
              <div className="option-icon existing-customer"></div>
              <h3>I'm an Existing Customer</h3>
              <p>I already have a CLOUD VIRTUE account and want to manage my hosting or add new services</p>
            </div>
            
            <div 
              className={`option-card ${userType === 'new' ? 'selected' : ''}`}
              onClick={() => setUserType('new')}
            >
              <div className="option-icon new-customer"></div>
              <h3>I'm a New Customer</h3>
              <p>I'm new to CLOUD VIRTUE and want to set up hosting for my website</p>
            </div>
          </div>
          
          <div className="step-actions">
            <button 
              className="primary-button" 
              onClick={nextStep} 
              disabled={userType === null}
            >
              {getButtonText()}
            </button>
          </div>
        </div>
      )
    },
    
    // Step 1: Domain question
    {
      title: 'Your Domain Status',
      content: (
        <div className="step-content domain-step">
          <h2>Do you already have a domain name?</h2>
          <p>A domain name is your website's address on the internet (e.g., yourbusiness.com)</p>
          
          <div className="option-cards">
            <div 
              className={`option-card ${hasDomain === true ? 'selected' : ''}`}
              onClick={() => setHasDomain(true)}
            >
              <div className="option-icon has-domain"></div>
              <h3>Yes, I have a domain</h3>
              <p>I'll use my existing domain with my new hosting</p>
            </div>
            
            <div 
              className={`option-card ${hasDomain === false ? 'selected' : ''}`}
              onClick={() => setHasDomain(false)}
            >
              <div className="option-icon no-domain"></div>
              <h3>No, I need a domain</h3>
              <p>I want to register a domain name for my website</p>
            </div>
          </div>
          
          {hasDomain === true && (
            <div className="domain-input-section">
              <label htmlFor="domainName">Please enter your domain name:</label>
              <input
                type="text"
                id="domainName"
                value={domainName}
                onChange={(e) => setDomainName(e.target.value)}
                placeholder="example.com"
              />
            </div>
          )}
          
          <div className="step-actions">
            <button className="secondary-button" onClick={prevStep}>Back to Step 1</button>
            <button 
              className="primary-button" 
              onClick={nextStep} 
              disabled={hasDomain === null || (hasDomain === true && !domainName)}
            >
              {getButtonText()}
            </button>
          </div>
        </div>
      )
    },
    
    // Step 2: Select Plan
    {
      title: 'Choose Your Hosting Plan',
      content: (
        <div className="step-content plans-step">
          <h2>Select the hosting plan that fits your needs</h2>
          <p>All plans include our reliable infrastructure and 24/7 technical support</p>
          
          <div className="plans-grid">
            {hostingPlans.map(plan => (
              <div 
                key={plan.id}
                className={`plan-card ${plan.popular ? 'popular' : ''} ${selectedPlan === plan.id ? 'selected' : ''}`}
                onClick={() => setSelectedPlan(plan.id)}
              >
                {plan.popular && <div className="popular-badge">Most Popular</div>}
                <h3>{plan.name}</h3>
                <div className="plan-price">
                  <span className="price">${plan.price}</span>
                  <span className="period">/month</span>
                </div>
                <ul className="plan-features">
                  <li><strong>{plan.storage} Storage</strong></li>
                  <li><strong>{plan.bandwidth} Bandwidth</strong></li>
                  <li><strong>{plan.websites} {typeof plan.websites === 'number' ? (plan.websites === 1 ? 'Website' : 'Websites') : plan.websites}</strong></li>
                  {plan.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
                <button 
                  className={`${selectedPlan === plan.id ? 'primary-button' : 'secondary-button'} select-plan-btn`}
                >
                  {selectedPlan === plan.id ? 'Selected' : 'Select Plan'}
                </button>
              </div>
            ))}
          </div>
          
          <div className="step-actions">
            <button className="secondary-button" onClick={prevStep}>Back to Step 2</button>
            <button 
              className="primary-button" 
              onClick={nextStep} 
              disabled={!selectedPlan}
            >
              {getButtonText()}
            </button>
          </div>
        </div>
      )
    },
    
    // Step 3: Configuration options
    {
      title: 'Configure Your Hosting',
      content: (
        <div className="step-content config-step">
          <h2>Customize Your Hosting Environment</h2>
          <p>These settings can be changed later in your control panel</p>
          
          <div className="config-options">
            <div className="config-option">
              <h4>Website Type</h4>
              <select defaultValue="wordpress">
                <option value="wordpress">WordPress</option>
                <option value="ecommerce">E-commerce Store</option>
                <option value="static">Static Website</option>
                <option value="custom">Custom Application</option>
              </select>
              <p className="option-hint">What type of website will you be hosting?</p>
            </div>
            
            <div className="config-option">
              <h4>Server Location</h4>
              <select defaultValue="us-east">
                <option value="us-east">US East (Virginia)</option>
                <option value="us-west">US West (Oregon)</option>
                <option value="eu-central">EU (Frankfurt)</option>
                <option value="asia-pacific">Asia Pacific (Singapore)</option>
              </select>
              <p className="option-hint">Choose a server location nearest to your target audience for best performance</p>
            </div>
            
            <div className="config-option">
              <h4>Add-Ons</h4>
              <div className="checkbox-group">
                <label>
                  <input type="checkbox" defaultChecked />
                  Daily Backups
                </label>
                <label>
                  <input type="checkbox" />
                  Security Suite
                </label>
                <label>
                  <input type="checkbox" />
                  Performance Optimization
                </label>
                <label>
                  <input type="checkbox" />
                  SEO Tools
                </label>
              </div>
              <p className="option-hint">Select additional services to enhance your hosting</p>
            </div>
          </div>
          
          <div className="step-actions">
            <button className="secondary-button" onClick={prevStep}>Back to Step 3</button>
            <button className="primary-button" onClick={nextStep}>
              {getButtonText()}
            </button>
          </div>
        </div>
      )
    },
    
    // Step 4: Summary & Checkout
    {
      title: 'Review & Complete',
      content: (
        <div className="step-content summary-step">
          <h2>Order Summary</h2>
          <p>Please review your order details before proceeding to checkout</p>
          
          <div className="order-summary">
            <div className="summary-item">
              <div className="summary-label">Account Type</div>
              <div className="summary-value">{userType === 'new' ? 'New Customer' : 'Existing Customer'}</div>
            </div>
            
            <div className="summary-item">
              <div className="summary-label">Domain</div>
              <div className="summary-value">
                {hasDomain ? `Using existing domain: ${domainName}` : 'New domain registration'}
              </div>
            </div>
            
            <div className="summary-item">
              <div className="summary-label">Hosting Plan</div>
              <div className="summary-value">
                {selectedPlan && hostingPlans.find(p => p.id === selectedPlan).name}
                <span className="price-tag">
                  ${selectedPlan && hostingPlans.find(p => p.id === selectedPlan).price}/mo
                </span>
              </div>
            </div>
            
            <div className="summary-item total-item">
              <div className="summary-label">Total (Monthly)</div>
              <div className="summary-value total-value">
                ${selectedPlan && hostingPlans.find(p => p.id === selectedPlan).price}
              </div>
            </div>
          </div>
          
          <div className="step-actions checkout-actions">
            <button className="secondary-button" onClick={prevStep}>Back to Step 4</button>
            <button 
              className="primary-button" 
              onClick={() => setIsPaymentModalOpen(true)}
            >
              Proceed to Checkout
            </button>
          </div>
        </div>
      )
    }
  ];
  
  return (
    <div className="get-started-page">
      <section className="wizard-section">
        <div className="container">
          {/* Progress indicators */}
          <div className="wizard-progress">
            {steps.map((step, index) => (
              <div 
                key={index}
                className={`wizard-step ${index === currentStep ? 'active' : ''} ${index < currentStep ? 'completed' : ''}`}
              >
                <div className="step-indicator">{index + 1}</div>
                <div className="step-title">{step.title}</div>
              </div>
            ))}
          </div>

          {/* Wizard content */}
          <div className="wizard-content">
            {steps[currentStep].content}
          </div>
        </div>
      </section>
      
      {/* Domain search modal */}
      {isModalOpen && (
        <div className="domain-modal-overlay">
          <div className="domain-modal-content">
            {isLoading && (
              <div className="domains-loading">
                <div className="spinner"></div>
                <p>Loading domain search...</p>
              </div>
            )}
            <div className="domain-modal-close-container">
              <button className="domain-modal-close" onClick={closeDomainModal}>
                <span className="close-icon">&times;</span>
                <span className="close-text">Close</span>
              </button>
            </div>
            <iframe 
              src="https://cloudvirtue.shopco.com/site/home"
              title="Domain Registration"
              className={`domains-iframe ${isLoading ? 'loading' : 'loaded'}`}
              onLoad={() => setIsLoading(false)}
              frameBorder="0"
            ></iframe>
          </div>
        </div>
      )}
      
      {/* Payment modal */}
      {isPaymentModalOpen && (
        <PaymentModal
          isOpen={isPaymentModalOpen}
          onClose={() => setIsPaymentModalOpen(false)}
          onSuccess={handlePaymentSuccess}
          amount={getSelectedPlanPrice()}
          description={getPlanDescription()}
        />
      )}
    </div>
  );
}

export default GetStarted;
