import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from '../common/Modal';
import AboutContent from '../modal-content/AboutContent';
import CareersContent from '../modal-content/CareersContent';
import ContactContent from '../modal-content/ContactContent';
import SupportContent from '../modal-content/SupportContent';

function Footer() {
  const [aboutModalOpen, setAboutModalOpen] = useState(false);
  const [teamModalOpen, setTeamModalOpen] = useState(false);
  const [careersModalOpen, setCareersModalOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [supportModalOpen, setSupportModalOpen] = useState(false);

  return (
    <footer className="main-footer">
      <div className="container">
        <div className="footer-grid">
          <div className="footer-column">
            <div className="footer-logo">
              <img src="/assets/images/logo.png" alt="CLOUD VIRTUE" />
            </div>
            <p>Empowering businesses with cutting-edge AI solutions, reliable web hosting, and domain services.</p>
            <div className="social-icons">
              <a href="https://github.com/cloudvirtue" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
                </svg>
              </a>
              <a href="https://www.linkedin.com/in/cloud-virtue" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                </svg>
              </a>
            </div>
          </div>
          
          <div className="footer-column">
            <h4>Services</h4>
            <ul>
              <li><Link to="/domains" onClick={() => window.scrollTo(0, 0)}>Domains</Link></li>
              <li><Link to="/hosting" onClick={() => window.scrollTo(0, 0)}>Hosting</Link></li>
              <li><Link to="/no-code-dev" onClick={() => window.scrollTo(0, 0)}>No-Code Dev</Link></li>
              <li><Link to="/no-code-request" onClick={() => window.scrollTo(0, 0)}>No-Code Request</Link></li>
              <li><Link to="/get-started" onClick={() => window.scrollTo(0, 0)}>Get Started</Link></li>
            </ul>
          </div>
          
          <div className="footer-column">
            <h4>Company</h4>
            <ul>
              <li><a href="#" onClick={(e) => { e.preventDefault(); setAboutModalOpen(true); }}>About Us</a></li>
              <li><a href="#" onClick={(e) => { e.preventDefault(); setTeamModalOpen(true); }}>Our Team</a></li>
              <li><a href="#" onClick={(e) => { e.preventDefault(); setCareersModalOpen(true); }}>No-Cod Dev's</a></li>
              <li><a href="#" onClick={(e) => { e.preventDefault(); setContactModalOpen(true); }}>Contact</a></li>
              <li><a href="#" onClick={(e) => { e.preventDefault(); setSupportModalOpen(true); }}>Support</a></li>
            </ul>
          </div>
          
          <div className="footer-column">
            <h4>Contact Us</h4>
            <p>Phone: (417) 413-4242</p>
            <p>Address: 1440 State Hwy 248, Ste Q 179</p>
          </div>
        </div>
        
        <div className="footer-bottom">
          <p>&copy; {new Date().getFullYear()} CLOUD VIRTUE. All rights reserved.</p>
          <div className="footer-links">
            <Link to="/privacy">Privacy Policy</Link>
            <Link to="/terms">Terms of Service</Link>
          </div>
        </div>
      </div>
      
      {/* About Us Modal */}
      <Modal 
        isOpen={aboutModalOpen}
        onClose={() => setAboutModalOpen(false)}
        title="About Us"
      >
        <AboutContent />
      </Modal>

      {/* Other modals will be added using the same template */}
      
      {/* Our Team Modal - will implement later */}
      <Modal 
        isOpen={teamModalOpen}
        onClose={() => setTeamModalOpen(false)}
        title="Our Team"
      >
        <div className="about-modal-content">
          <div className="modal-hero">
            <div className="modal-hero-content team-modal-content">
              <div className="modal-text team-intro">
                <p>Our team consists of dedicated AI specialists, cloud engineers, and business strategists who bring years of experience to every project. We're passionate about technology and committed to helping our clients succeed.</p>
                <p>With diverse backgrounds and skill sets, we approach each challenge with creative solutions and innovative thinking.</p>
              </div>
              <div className="team-graphic-section">
                <p className="ai-team-note-small">The AI generated graphic is clever BUT... true. When you hire us, your first cost saving is you hire a small team, often just one dev that has the power of a whole corporation at their fingertips!</p>
                <img src="/assets/images/about/our-team.png" alt="CLOUD VIRTUE Team" className="team-graphic" />
              </div>
            </div>
          </div>
          
          {/* Team members would go here */}
          <p className="coming-soon">Team profiles coming soon</p>
        </div>
      </Modal>

      {/* Careers Modal */}
      <Modal 
        isOpen={careersModalOpen}
        onClose={() => setCareersModalOpen(false)}
        title="No-Cod Dev's"
      >
        <CareersContent />
      </Modal>

      {/* Contact Modal */}
      <Modal 
        isOpen={contactModalOpen}
        onClose={() => setContactModalOpen(false)}
        title="Contact"
      >
        <ContactContent />
      </Modal>

      {/* Support Modal */}
      <Modal 
        isOpen={supportModalOpen}
        onClose={() => setSupportModalOpen(false)}
        title="Support"
      >
        <SupportContent />
      </Modal>
    </footer>
  );
}

export default Footer;
