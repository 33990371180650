import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../common/Icon';
import ArticleModal from '../../modals/ArticleModal';
import RssFeedService from '../../../services/RssFeedService';
import { formatUpdateTime } from '../../../utils/dateUtils';
import './styles/heroes.css';

function HomeHero() {
  const [isArticleModalOpen, setIsArticleModalOpen] = useState(false);
  const [lastUpdateTime, setLastUpdateTime] = useState('');
  const [featuredArticle, setFeaturedArticle] = useState({
    title: 'No-Code Development Guide',
    excerpt: 'Learn how to build powerful applications without writing a single line of code'
  });
  
  useEffect(() => {
    // Get the last fetch time from RssFeedService
    const lastFetchTime = RssFeedService.getLastFetchTime();
    
    if (lastFetchTime) {
      // Format the time
      setLastUpdateTime(formatUpdateTime(lastFetchTime));
    } else {
      // If no last fetch time, use current time
      setLastUpdateTime(formatUpdateTime(new Date()));
    }
    
    // Get the latest article to feature
    const fetchLatestArticle = async () => {
      try {
        // Try to get articles from cache first for faster loading
        const cachedArticles = RssFeedService.getCachedArticles().articles;
        
        if (cachedArticles && cachedArticles.length > 0) {
          updateFeaturedArticle(cachedArticles);
        } else {
          // If no cached articles, fetch fresh ones
          const articles = await RssFeedService.fetchArticles();
          if (articles && articles.length > 0) {
            updateFeaturedArticle(articles);
          }
        }
      } catch (error) {
        console.warn('Could not fetch latest article for hero card:', error);
        // Keep default article text if there's an error
      }
    };
    
    fetchLatestArticle();
  }, []);
  
  // Update the featured article with the most relevant article from the list
  const updateFeaturedArticle = (articles) => {
    // First try to find an AI-related article
    const aiArticle = articles.find(article => 
      article.title.toLowerCase().includes('ai') || 
      article.excerpt.toLowerCase().includes('artificial intelligence') ||
      article.category === 'ai-integrations'
    );
    
    // Then try to find a no-code article
    const noCodeArticle = articles.find(article => 
      article.title.toLowerCase().includes('no-code') || 
      article.excerpt.toLowerCase().includes('no-code') ||
      article.category === 'tools'
    );
    
    // Use the first article as a fallback
    const articleToFeature = aiArticle || noCodeArticle || articles[0];
    
    if (articleToFeature) {
      setFeaturedArticle({
        title: articleToFeature.title,
        excerpt: articleToFeature.excerpt
      });
    }
  };
  
  const openArticleModal = () => {
    setIsArticleModalOpen(true);
  };
  
  const closeArticleModal = () => {
    setIsArticleModalOpen(false);
  };

  return (
    <section className="page-hero">
      <div className="container">
        <div className="hero-content-wrapper">
          <div className="hero-text-content">
            <h1>CLOUD VIRTUE for <br/><span className="highlight-text">Tomorrow's Business</span></h1>
            <p className="lead">CLOUD VIRTUE combines cutting-edge solutions with enterprise cloud services to drive your business forward</p>
            <div className="hero-buttons">
              <Link to="/get-started" className="primary-button">Start Your Project</Link>
              <a href="#services" className="secondary-button">Explore Services</a>
            </div>
          </div>
          <div className="hero-image-content">
            <div className="featured-article-card">
              <div className="updated-timestamp">
                Updated: {lastUpdateTime}
              </div>
              <h3>{featuredArticle.title}</h3>
              <p className="article-excerpt">{featuredArticle.excerpt}</p>
              <button onClick={openArticleModal} className="card-link-button">
                <span className="link-text">Read Article</span>
                <Icon name="arrowRight" size={20} />
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <ArticleModal 
        isOpen={isArticleModalOpen} 
        onClose={closeArticleModal} 
        category="tools" 
      />
    </section>
  );
}

export default HomeHero;