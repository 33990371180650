import React, { useEffect } from 'react';
import '../../styles/modals/consultation-modal.css';
import '../../styles/components/modal-header-fix.css';

const ConsultationModal = ({ isOpen, onClose }) => {
  // Load Calendly script
  useEffect(() => {
    if (isOpen) {
      const head = document.querySelector('head');
      const script = document.createElement('script');
      script.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('async', true);
      head.appendChild(script);

      return () => {
        // Clean up - remove the script when component unmounts or modal closes
        if (head && script) {
          head.removeChild(script);
        }
      };
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="consultation-modal">
        <button className="modal-close" onClick={onClose} aria-label="Close modal">×</button>
        <div className="consultation-modal-content">
          <h2 className="modal-title" style={{ color: "#111827 !important", fontWeight: "bold" }}>Schedule a Consultation with CLOUD VIRTUE</h2>
          <p className="modal-subtitle">Take the first step toward AI-driven business transformation</p>
          
          <div className="calendly-wrapper">
            <div 
              className="calendly-inline-widget" 
              data-url="https://calendly.com/cloudvirtue"
              style={{ width: "100%" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsultationModal; 